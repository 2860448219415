import axiosAuthMiddleware from '../middleware/middleware';

export const GetMilkSoldToList = async (id) => {
    const url = `/milk_to_sold_list?project_id=${id}`;
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain',
        },
    });
    return await res;
}

export const GetStatusList = async () => {
    const url = `/status_list`;
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain',
        },
    });
    return await res;
}
export const GetFarmStateList = async (id) =>{
    const url = `/state_list?project_id=${id}`
    const res = axiosAuthMiddleware.request({
        url:url,
        method:'GET',
        headers:{
            'Content-Type':'text/plain'
        }
    })
    return await res;
}

export const CarbonCreditsViewDataFiltered = async(data) =>{
    const url = `/carbon_credits_view_data_filtered`
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        data:data
    });
    return await res;
}

export const CarbonCreditsViewData = async(id) => {
    const url = `/carbon_credits_view_data?project_id=${id}`
    const res = axiosAuthMiddleware.request({
        url:url,
        method:"GET",
        headers: {
            'Content-Type' :'text/plain'
        },
    })
    return await res;
}
