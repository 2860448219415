import axios from 'axios';
import { BASE_URL } from '../config';
import formatResponse from './format-response';
import Cookies from 'js-cookie';


const token = () => {
  // const getToken = localStorage.getItem('auth_token');
  const getToken = Cookies.get('auth_token') || null; // => 'value'
  return getToken;
};

const generalMiddleware = axios.create({
  baseURL: BASE_URL,
});

generalMiddleware.interceptors.request.use((requestConfig) => {
  requestConfig.headers = {
    // 'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token()}`,
    // 'ngrok-skip-browser-warning': true,
  };
  return requestConfig;
});

export const instanc = generalMiddleware;

export default formatResponse(generalMiddleware);
