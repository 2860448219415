import React from 'react';
import './Button.css';
import { TailSpin } from 'react-loader-spinner';
import LeftA from "../../images/Facilitator/LeftA.png"
import RightA from "../../images/Facilitator/RightA.png"

export const Button = ({
  maxWidth,
  width,
  bgColor,
  text,
  textColor,
  handleClick,
  isLoading,
}) => {
  return (
    <>
      <div
        style={{
          width: width ? width : '100%',
          maxWidth: maxWidth ? maxWidth : '',
          backgroundColor: bgColor ? bgColor : 'var(--color-primary)',
        }}
        className="button"
        onClick={() => {
          if (!isLoading) {
            handleClick();
          }
        }}
      >
        {isLoading ? (
          <>
            <TailSpin
              height="25px"
              width="25px"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              strokeWidth={5}
              visible={true}
            />
          </>
        ) : (
          <>
            <div
              className=""
              style={{ color: textColor ? textColor : 'white' }}
            >
              {text}
            </div>
          </>
        )}
      </div>
    </>
  );
};


export const ButtonWithArrow = ({
  maxWidth,
  width,
  bgColor,
  text,
  textColor,
  handleClick,
  isLoading,
  reverse
}) => {
  return (
    <>
      <div
        style={{
          width: width ? width : '100%',
          maxWidth: maxWidth ? maxWidth : '',
          backgroundColor: bgColor ? bgColor : 'var(--color-primary)',
          flexDirection: reverse ? 'row-reverse' : 'row',
        }}
        className="button button-arrow"
        onClick={() => {
          if (!isLoading) {
            handleClick();
          }
        }}
      >
        {isLoading ? (
          <>
            <TailSpin
              height="25px"
              width="25px"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              radius="1"
              strokeWidth={5}
              visible={true}
            />
          </>
        ) : (
          <>
            <div>
              <img src={reverse ? RightA:LeftA} alt="" />
            </div>
            <div
              className=""
              style={{ color: textColor ? textColor : 'white' }}
            >
              {text}
            </div>
          </>
        )}
      </div>
    </>
  );
};

