
export default function formatResponse(instance) {
  instance.interceptors.response.use(
    (resolveRes) => {
      return {
        data: resolveRes?.data,
        status: resolveRes?.status,
        success: true,
      };
    },
    (error) => {
      const { message, status } = error?.response;
      const { message:errorMsg } = error?.response?.data;
      // if(response.status === 401){
      // }
      return {
        data: message || errorMsg || 'Something went wrong',
        status: status || NaN,
        success: false,
      };
    }
  );
  return instance;
}

