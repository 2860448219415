export const percentFat = [
    { name: "4-6%", id: 1 },
    { name: "<4%", id: "2" },
];

export const breedList = [
    { name: "Crossbred", id: 5 },
    { name: "Holstein", id: 8 },
    { name: "Jersey", id: 9 },
  ];

export const grams_per_list = [{ id: 1, name: 1 }];

export const milk_sold_to_list = [
    { id: 1, name: "Addison" },
    { id: 2, name: "Advanced Milk Commodities" },
    { id: 3, name: "Agri Mark" },
    { id: 4, name: "Agropur" },
    { id: 5, name: "Baker Cheese" },
    { id: 6, name: "Burnett Dairy Cooperative" },
    { id: 7, name: "california dairy inc" },
    { id: 8, name: "Cobblestone" },
    { id: 9, name: "Dannon" },
    { id: 10, name: "Darigold" },
    { id: 11, name: "Dairy Farmers of America" },
    { id: 12, name: "First Dist. Assoc." },
    { id: 13, name: "First District Assn." },
    { id: 14, name: "Foremost Farms" },
    { id: 15, name: "Grande Cheese" },
    { id: 16, name: "Grassland Dairy Products Inc." },
    { id: 17, name: "Hilmar Cheese" },
    { id: 18, name: "Holland Patent Coop" },
    { id: 19, name: "Independent" },
    { id: 20, name: "Land O' Lakes" },
    { id: 21, name: "Maryland Virginia" },
    { id: 22, name: "Middlebury" },
    { id: 23, name: "Michigan Milk Producers Association" },
    { id: 34, name: "Milk Board"},
    { id: 24, name: "Mullins Cheese" },
    { id: 25, name: "NFO" },
    { id: 26, name: "Point Reyes Farmstead Cheese" },
    { id: 27, name: "Rothenbuhler Cheese" },
    { id: 28, name: "Rutters Independen" },
    { id: 29, name: "Saputo" },
    { id: 30, name: "Sartori Cheese COo." },
    { id: 35, name: "The Alberta Milk Board"},
    { id: 31, name: "Upstate" },
    { id: 32, name: "Valley Milk" },
    { id: 33, name: "WestCo" },
  ]


export const dmi_validation = [
    {
      breed: "Crossbred",
      min: 43,
      max: 50,
    },
    {
      breed: "Holstein",
      min: 53,
      max: 64,
    },
    {
      breed: "Jersey",
      min: 42,
      max: 46,
    },
  ];
