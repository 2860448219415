import { BASE_URL } from '../config';
import axiosAuthMiddleware from '../middleware/middleware';

export const getProjectList = async () => {
  const res = await axiosAuthMiddleware.request({
    url: `${BASE_URL}/project_list`,
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
  return await res;
};

export const getAllProjects = async () => {
  const res = await axiosAuthMiddleware.request({
    url: `${BASE_URL}/projects_with_manage_access`,
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
  return await res;
};
