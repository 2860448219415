import React, { useEffect, useRef, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  InputAutoAddress,
  InputBoxWithDropdown,
} from '../../Components/InputFields/InputField';
import './CarbonCredits.css';
import GoogleMapsComp from '../../Components/GoogleMapsComponent/GoogleMapsComp';
import {
  GetMilkSoldToList,
  GetStatusList,
  CarbonCreditsViewDataFiltered,
  GetFarmStateList,
  CarbonCreditsViewData,
} from '../../api/carbonCredits/endpoints';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner';
import {
  defaultcenter,
  mapOptions,
  DistanceList,
  initialData,
} from './constants';
import MultipleSelect from '../../Components/InputTag/MultipleSelect';
import FarmImg from '../../images/Analytics/FarmImg.svg';
import CowImg from '../../images/Analytics/CowImg.svg';
import CarbonImg from '../../images/Analytics/Co2.svg';
const libraries = ['places'];

const CarbonCredits = () => {
  const inputRef = useRef();
  const { selectedProject } = useSelector((state) => state.project);
  const { isLoaded: mapLoader } = useJsApiLoader({
    libraries,
    options: mapOptions,
    googleMapsApiKey: 'AIzaSyBF56EGT6qo3RjCpDIlMVFQG83DxAlyBpA',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [center, setCenter] = useState(defaultcenter);
  const [marker, setMarker] = useState(null);
  const [farmLocationMarkers, setFarmLocationMarkers] = useState([]);
  const [MilkSoldToValue, setMilkSoldToValue] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [farmStateValue, setFarmStateValue] = useState([]);
  const initialCardData = [
    {
      id: 1,
      title: 'Total Farms in the Selection',
      count: null,
      image: FarmImg,
    },
    {
      id: 2,
      title: 'Total Cows',
      count: null,
      image: CowImg,
    },
    {
      id: 3,
      title: 'Total Carbon Credits in the Selection',
      count: null,
      image: CarbonImg,
    },
  ];
  const initialState = [
    {
      key: 0,
      label: 'Address',
      type: 'text',
      value: '',
      id: 'address',
      name: 'address',
      error: false,
      notRequired: true,
    },
    {
      key: 1,
      label: 'Distance',
      type: 'dropdown',
      value: '500 miles',
      error: false,
      list: DistanceList,
      id: 'distance',
      disabled: false,
      notRequired: true,
    },
    {
      key: 2,
      label: 'Milk Sold to',
      type: 'dropdown-tag',
      values: MilkSoldToValue,
      setValue: setMilkSoldToValue,
      error: false,
      list: [],
      id: 'milk_sold_to',
      disabled: false,
      notRequired: true,
    },
    {
      key: 3,
      label: 'Farm State',
      type: 'dropdown-tag',
      error: false,
      values: farmStateValue,
      setValue: setFarmStateValue,
      list: [],
      id: 'farm_state',
      disabled: false,
      notRequired: true,
    },
    {
      key: 4,
      label: 'Farm Status',
      type: 'dropdown-tag',
      values: statusValue,
      setValue: setStatusValue,
      error: false,
      list: [],
      id: 'farm_status',
      disabled: false,
      notRequired: true,
    },
  ];
  const [data, setData] = useState(initialData);
  const [formDetails, setFormDetails] = useState(initialState);
  const [cardDetails, setCardDetails] = useState(initialCardData);
  const [initialMP, setInitialMP] = useState();
  const [clear, setClear] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [country, setCountry] = useState('canada');
  const [carboncredittt, setCarboncredittt] = useState();

  // function to fetch data
  const fetchData = async () => {
    console.log('=======FETCH DATA=======');
    const resMilk = await GetMilkSoldToList(selectedProject.id);
    const resState = await GetFarmStateList(selectedProject.id);
    const resStatus = await GetStatusList();

    // Deciding if to fetch full data or filtered data
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== null)
    );


    const carboncredit = await CarbonCreditsViewData(selectedProject.id);



    if (
      (resMilk.status === 200 || resMilk.status === 201) &&
      (resStatus.status === 200 || resStatus.status === 201) &&
      (resState.status === 200 || resState.status === 201) &&
      (carboncredit.status === 200 || carboncredit.status === 201)
    ) {
      const milkData = [];
      const statusData = [];
      const stateData = [];

      //mapping data
      resMilk?.data?.result.sort().forEach((values, index) => {
        milkData.push({ id: index + 1, name: values });
      });
      resStatus?.data?.result.sort().forEach((values, index) => {
        statusData.push({ id: index + 1, name: values });
      });
      resState?.data?.result.sort().forEach((values, index) => {
        stateData.push({ id: index + 1, name: values });
      });
      setCarboncredittt(carboncredit.data.carbon_credit);

      //updating initial state
      const updated = formDetails.map((details) => {
        if (details.id === 'farm_status') {
          return { ...details, list: statusData, isError: false };
        } else if (details.id === 'milk_sold_to') {
          return { ...details, list: milkData, isError: false };
        } else if (details.id === 'farm_state') {
          return { ...details, list: stateData, isError: false };
        }
        return { ...details };
      });
      setFormDetails(updated);
      setIsLoading(false);
    }
  };

  const handleClear = async () => {
    setClear(true);
    setMarker(null);
    setFormDetails(initialState);
    setData(initialData);
  };

  const handleValueChange = (label, value) => {
    let updated = formDetails.map((dets) => {
      if (dets.label === label) {
        return { ...dets, value: value };
      }
      return { ...dets };
    });
    setFormDetails(updated);
  };

  //update in farm state or farm provinces
  useEffect(() => {
    let updated = formDetails.map((dets) => {
      if (dets.id === 'farm_state') {
        if (country === 'USA') return { ...dets, label: 'Farm State' };
        else
          return {
            ...dets,
            label: 'Farm Provinces',
          };
      } else return { ...dets };
    });
    setFormDetails(updated);
  }, [country, clear]);

  useEffect(() => {
    const sendData = async (data) => {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== null)
      );

      if (Object.keys(filteredData).length > 1) {
        const response = await CarbonCreditsViewDataFiltered(filteredData);
        if (response.status === 200 || response.status === 201) {
          setFarmLocationMarkers(response.data.farm_locations);
          let updatedCard = cardDetails.map((dets) => {
            console.log('DETS:', dets);
            if (dets.id === 1) {
              return { ...dets, count: response.data.total_farms };
            } else if (dets.id === 2) {
              return { ...dets, count: response.data['total_cows'] };
            } else {
              return { ...dets, count: response.data.total_credits };
            }
          });
          setCardDetails(updatedCard);
          setInitialMP([]);
          setClear(false);
        }
      }
    };

    if (selectedProject) {
      sendData({ ...data, project_id: selectedProject.id });
    }
  }, [data, updated, selectedProject]);

  console.log('STATE:',clear,selectedProject);
  useEffect(() => {
    if (selectedProject !== null) {
      fetchData();
    }
  }, [clear, selectedProject]);

  //function to handle address value Change
  const handleAddressChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      let updated = formDetails.map((dets) => {
        if (dets.label === 'Address') {
          return { ...dets, value: place.name };
        }
        return { ...dets };
      });
      setFormDetails(updated);


      const { lat, lng } = place.geometry.location;
      setCenter({ lat: lat(), lng: lng() });
      setMarker({ lat: lat(), lng: lng() });
      setData((prevData) => ({
        ...prevData,
        latitude: lat(),
        longitude: lng(),
        distance: parseInt(formDetails[1].value),
      }));
      setUpdated(!updated);
    }
  };

  const handleSelect = (label, value) => {
    const fields = Array.from(formDetails);
    const updatedFields = fields.map((data) => {
      if (data.label === label) {
        data.id === 'distance'
          ? setData((prevData) => ({ ...prevData, [data.id]: parseInt(value) }))
          : setData((prevData) => ({ ...prevData, [data.id]: value }));
        return { ...data, isError: false, value: value };
      }
      return { ...data };
    });
    setFormDetails(updatedFields);
    setUpdated(!updated);
  };

  const handleMultiSelect = (data, label) => {
    let updated = formDetails.map((details) => {
      if (details.label === label) {
        setData((prevData) => ({
          ...prevData,
          [details.id]: data,
        }));
        return { ...details, values: data };
      } else {
        return { ...details };
      }
    });
    setFormDetails(updated);
  };

  return isLoading ? (
    <>
      <div className="spin-body">
        <TailSpin
          height="60px"
          width="60px"
          color="var(--color-primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          strokeWidth={4}
          visible={true}
        />
      </div>
    </>
  ) : (
    <>
      <div className="carbon-container">
        <div className="fileds">
          <div className="Filter-field">
            {formDetails.map((details, index) =>
              details.type === 'dropdown' ? (
                <>
                  <InputBoxWithDropdown
                    list={details.list}
                    label={details.label}
                    value={details.value}
                    isError={details.error}
                    disable={details.disabled}
                    actualIndex={details.key}
                    outerindex={index}
                    notRequired={details.notRequired}
                    onSelect={handleSelect}
                  />
                </>
              ) : (
                details.type === 'text' && (
                  <>
                    <InputAutoAddress
                      label={details.label}
                      value={details.value}
                      type={details.type}
                      isError={details.error}
                      handlePlaceChanged={handleAddressChange}
                      notRequired={details.notRequired}
                      handleValueChange={handleValueChange}
                      isLoaded={mapLoader}
                      inputRef={inputRef}
                    />
                  </>
                )
              )
            )}
            <div className="clear-button" onClick={handleClear}>
              Clear
            </div>
          </div>
          <div className="sub-fields">
            {formDetails.map(
              (details, index) =>
                details &&
                details.type === 'dropdown-tag' && (
                  <>
                    <div className="field-dropdown-tag">
                      <div className="input-box">
                        <div className="label">{details.label}</div>
                        <div>
                          <MultipleSelect
                            checked={details.values}
                            handleMultiSelect={handleMultiSelect}
                            allOptionsEmail={details.list || []}
                            isError={details.error}
                            showAddButton={false}
                            isFilter={true}
                            label={details.label}
                            setUpdated={setUpdated}
                            updated={updated}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
            )}
          </div>
        </div>
        <div className="geo-map">
          <GoogleMapsComp
            center={center}
            markerPoint={marker}
            isLoaded={mapLoader}
            mapOptions={mapOptions}
            initialMP={initialMP}
            setInitialMP={setInitialMP}
            farmLocationMarkers={farmLocationMarkers}
            cardDetails={cardDetails}
            setCardDetails={setCardDetails}
            clear={clear}
            selectedProject={selectedProject?.id || null}
            country={country}
            setCountry={setCountry}
          />
        </div>
        <div className="carbon-cards">
          {cardDetails.map((cardInfo, index) => (
            <div className="custome-card" key={index}>
              <div className="card-details">
                <div className="card-name">{cardInfo.title}</div>
                <div className="card-count">
                  {cardInfo.count === null ? 0 : cardInfo.count}
                </div>
              </div>
              <div>
                <img src={cardInfo.image} alt="" />
              </div>
            </div>
          ))}
        </div>
        <div className="table-container-analytics">
          <div className="table-body-analytics">
            <div
              className="table-header"
              style={{
                paddingTop: '14px',
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
            >
              <div className="facilator-head-analytics">Milk Sold To</div>
              <div className="farm-head-analytics">Credits</div>
            </div>
            <div className="border-bottom-analytics"></div>
            {carboncredittt &&
              Object.keys(carboncredittt)
                .reverse()
                .map((key, index1) => {
                  return (
                    <React.Fragment key={index1 + 1}>
                      <div className="table-row-analytics">
                        <div className="facilator-head-analytics">{key}</div>
                        <div className="farm-head-analytics">
                          {carboncredittt[key]}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarbonCredits;
