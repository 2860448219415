import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projectList: null,
  selectedProject: null,
  allProjectList:null
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    reduxProjectList: (state, action) => {
      state.projectList = action.payload;
    },
    reduxSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    reduxAllProjectList: (state, action) => {
      state.allProjectList = action.payload
    }
  },
});

export const { reduxProjectList, reduxSelectedProject, reduxAllProjectList } =
  projectSlice.actions;
export default projectSlice.reducer;
