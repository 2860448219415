import React, { useEffect, useState } from 'react';
// import Chips from "react-chips/lib/Chips";
// import Chips from "react-chips/lib/Chips";

import Close from '../../images/Facilitator/Close.svg';
import Email from '../../images/Facilitator/SMS.svg';
import CloseChip from '../../images/Facilitator/cancelChip.svg';

import './AddEmailChip.css';
import { InputBox, InputBoxWithDropdown } from '../InputFields/InputField';
import { TailSpin } from 'react-loader-spinner';


const AddFacilitatorchipModal = ({
  isOpen,
  setIsOpen,
  checked,
  setChecked,
  setAllEmailOptions,
  facilitatorDetails,
  handleFacilitator,
  handleChangeFacilitatorDetails,
  isLoadingAddUser,
  handleRemoveFacilitator,
  companyList,
  handleCompanySelect,
  selectedCompany,
}) => {
  const [email, setEmail] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [isShowError, setIsShowError] = useState(false);
  const [prevEmailInput, setPrevEmailInput] = useState({
    data: '',
    update: false,
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [tags, setTags] = useState([]);

  // useEffect(() => {
  //   if (prevEmailInput.update) {
  //     setEmailInput(prevEmailInput.data);
  //     setIsShowError(true);
  //     setPrevEmailInput((state) => {
  //       return { ...state, update: false };
  //     });
  //   }
  // }, [prevEmailInput.update]);

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleAddEmail = () => {
    const isValid = true;
    let isTagValid = true;
    if (tags?.length > 0) {
      const tagsArray = tags.reduce((pre, cur) => {
        return pre && validateEmail(cur);
      }, true);
      isTagValid = tagsArray;
    }

    // if (isValid && isTagValid) {
    //   let tmp = Array.from(checked);
    //   if (checked.includes(emailInput)) {
    //     // setErrMsg("Email address already exist");
    //     return;
    //   }
    //   // Continue update state
    //   if (emailInput?.length) {
    //     tmp.push(emailInput);
    //   }

    //   if (tags?.length > 0) {
    //     tmp = [...tmp, ...tags];
    //   }
    //   setChecked(tmp);

    //   let EmailOption = [];
    //   if (tags?.length > 0) {
    //     const emailTagsOption = tags.map((dataE) => {
    //       return { value: dataE, label: dataE };
    //     });
    //     EmailOption = [...EmailOption, ...emailTagsOption];
    //   }

    //   if (emailInput?.length) {
    //     EmailOption.push({ value: emailInput, label: emailInput });
    //   }
    //   setAllEmailOptions((state) => [...state, ...EmailOption]);
    //   setIsOpen(false);
    // } else {
    //   setIsShowError(true);
    // }
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            zIndex: 999,
            transform: 'translate(-50%,-50%)',
            borderRadius: '12px',
            boxShadow: '0px 10px 30px rgba(60, 66, 87, 0.15)',
          }}
          className="modal-content2"
        >
          <div className="modal-inner">
            <img
              alt=""
              src={Close}
              className=""
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <div className="modal-newText" style={{ fontSize: '20px' }}>
            Add New Facilitator to CarbonCredify
          </div>

          <div>
            {facilitatorDetails?.map((items, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    className=""
                    style={{ width: '100%', marginTop: '16px' }}
                  >
                    <InputBox
                      label={items.label}
                      type={items.type}
                      value={items.values}
                      handleChange={handleChangeFacilitatorDetails}
                      isError={items.error}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          {isShowError && (
            <div className="validation-text">
              Please enter a valid email address
            </div>
          )}
          <div style={{ marginTop: '16px' }}>
            <InputBoxWithDropdown
              list={companyList}
              label="Company"
              // placeholder="Select a Company"
              value={selectedCompany?.company_name}
              onSelect={handleCompanySelect}
              isError={false}
              isCompany={true}
            />
          </div>
          <div
            className="cancle-modal-faci"
            style={{ gap: '15px', marginTop: '16px' }}
          >
            <div
              className="cancle-modal-content"
              style={{
                width: '50%',
                borderRadius: '6px',
                background: '#788B9A',
                color: 'white',
                height: '50px',
              }}
              onClick={() => {
                setIsOpen(false);
                handleRemoveFacilitator();
              }}
            >
              Cancel
            </div>
            {isLoadingAddUser ? (
              <>
                <div
                  className="add-content"
                  style={{
                    width: '50%',
                    borderRadius: '6px',
                    background: ' #3BB14A',
                    height: '50px',
                    cursor: 'unset !important',
                  }}
                >
                  <TailSpin
                    height="25px"
                    width="25px"
                    color="var(--color-primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    strokeWidth={4}
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className="add-content"
                  style={{
                    width: '50%',
                    borderRadius: '6px',
                    background: '#3BB14A',
                    height: '50px',
                  }}
                  onClick={handleFacilitator}
                >
                  Add
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddFacilitatorchipModal;
