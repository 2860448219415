import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    facilitatorData: null
}

export const facilitatorDataSlice = createSlice({
  name: 'facilitator_portfolio',
  initialState,
  reducers: {
    reduxfacilitatorData: (state, action) => {
      state.facilitatorData = action.payload;
    },
  },
});

export const { reduxfacilitatorData } = facilitatorDataSlice.actions;
export default facilitatorDataSlice.reducer;
