import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modals.css';
import { InputTextArea } from '../InputFields/InputField';
import { Button } from '../Button/Button';
import { sendEmailToReject } from '../../api/Farm/endpoints';
import { toastFailure, toastSuccess } from '../Toaster/Toast';

const RejectModal = ({ isModalOpen, setIsModalOpen, uniqueId , setFarmFormStatus}) => {
  const [addressValue, setAddressValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (label, e) => {
    setAddressValue(e.target.value);
  };

  const handleSend = async () => {
    setIsLoading(true);
    if (addressValue.trim() === "") {
      toastFailure("Please write a reason")
      setIsLoading(false)
    } else {
      const data = {
        reason: addressValue,
        unique_key: uniqueId,
      };
      const responseEmail = await sendEmailToReject(data);
      if (responseEmail.status === 200 || responseEmail.status === 201) {
        toastSuccess(responseEmail.data.message);
        setIsLoading(false);
        setIsModalOpen(false);
        setFarmFormStatus("Farmer Pending")
        setAddressValue('');
      } else {
        setIsLoading(false);
        toastFailure(responseEmail.data);
      }
    }
    
  };
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
          setAddressValue('');
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="reject-modal"
      >
        <Modal.Body>
          <div className="custom-modal-content" style={{padding:"12px 20px"}}>
            <div className="reject-title-cls">Are you sure want to reject?</div>
            <div className="reject-body-content">
              <div className="form-field" style={{ width: '100%' }}>
                <InputTextArea
                  label="Reason"
                  height="120px"
                  type="address"
                  value={addressValue}
                  handleChange={handleChange}
                />
              </div>
              <div className="buttons-reject">
                <Button
                  text="Send"
                  width="160px"
                  handleClick={() => handleSend()}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RejectModal;
