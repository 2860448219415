import React, { useState, useEffect } from 'react';
import LogoMrv from '../../../images/Auth/SVG 1.svg';
import { InputField } from '../../../Components/InputFields/InputField';
import {Button} from '../../../Components/Button/Button';
import BackArrow from '../../../images/Auth/BackArrow.svg';
import SuccessFull from '../../../images/Auth/PasswordChange.svg';
import '../Login/Login.css';
import './ForgotPassword.css';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import { toastFailure, toastSuccess } from '../../../Components/Toaster/Toast';
import {
  useEmailOtpVarification,
  useResetPassword,
  useSendEmailOtp,
} from '../../../api/auth/hooks';

let message;
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isCallApi, setIsCallApi] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [isSuccessfullyChangePassword, setIsSuccessfullyChangePassword] =
    useState(false);
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [counter, setCounter] = useState(30);

  const { mutate: sendEmailOtp, isLoading: isOtpSending } = useSendEmailOtp();
  const { mutate: verifyOtp, isLoading: isVerified } =
    useEmailOtpVarification();
  const { mutate: resetPasswordCall, isLoading: isPasswordReset } =
    useResetPassword();

  useEffect(() => {
    let interval;

    if (counter > 0 && otpSent) {
      interval = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [counter, otpSent]);

  const formattedSeconds = formatSeconds(counter);

  function formatSeconds(seconds) {
    return String(seconds).padStart(2, '0');
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value.toString().trim());
    setEmailError(false);
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setPassword({
      ...password,
      [name]: value.toString().trim(),
    });
  };

  const sendOtpViaEmail = (formData) => {
    const data = {
      email: formData,
    };
    sendEmailOtp(data, {
      onSuccess: ({ data, success }) => {
        if (data.status === 200 || data.status === 201) {
          setOtpSent(true);
          toastSuccess(data.message)
          setIsCallApi(false);
        } else {
          setOtpSent(true);
          toastFailure(data.message)
          setIsCallApi(false);
        }
      },
      onError: (error) => {
        // setOtpSent(true);
        setIsCallApi(false);
      },
    });
  };

  const verifyOtpFunc = (formData) => {
    const data = {
      email: formData.email,
      otp: formData.otp,
    };
    verifyOtp(data, {
      onSuccess: ({ data }) => {
        if (data.status === 200 || data.status === 201) {
          setOtpVerified(true);
          toastSuccess(data.message);
          setIsCallApi(false);
        } else {
          toastFailure(data.message);
          setCounter(0);
          setIsCallApi(false);
        }
      },
      onError: (error) => {
        // setOtpVerified(true);
        setIsCallApi(false);
      },
    });
  };

  const resetPasswordFun = (formData) => {
    const data = {
      email: formData.email,
      password: formData.password,
      otp: otp,
    };
    resetPasswordCall(data, {
      onSuccess: ({ data }) => {
        if (data.status === 200 || data.status === 201) {
          setIsCallApi(false);
          toastSuccess(data.message)
          navigate('/login')
        } else {
          setIsCallApi(false);
          toastFailure(data.message);
        }
      },
      onError: (error) => {
        setIsCallApi(false);
      },
    });
  };

  const handleSubmitEmail = () => {
    setIsCallApi(true);
     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.toString().trim() === '') {
      message = 'Please enter email';
      toastFailure(message);
      setIsCallApi(false);
    } else if (emailPattern.test(email) === false) {
      message = 'Email format is not valid';
      toastFailure(message);
      setIsCallApi(false);
    } else {
      sendOtpViaEmail(email);
      // setOtpSent(true)
    }
  };

  const handleSubmitOtp = () => {
    setIsCallApi(true);
    if (otp.trim() === '') {
      message = 'Please enter a OTP';
      toastFailure(message);
    } else if (otp.length < 6) {
      toastFailure('Please enter a 6 digit OTP');
    } else {
      const formData = { email: email, otp: otp };
      verifyOtpFunc(formData);
      // setOtpVerified(true);
    }
  };
  const handleSubmitPassword = () => {
    setIsCallApi(true);
    if (password.newPassword !== password.confirmPassword) {
      message = 'Password not matched';
      toastFailure(message);
      setIsCallApi(false);
    } else if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
        password.newPassword
      ) === false
    ) {
      message =
        'Password must contain at least one small and capital letter, special characters and numbers.';
      setIsCallApi(false);
      toastFailure(message);
    } else {
      const data = {
        email: email,
        password: password.newPassword,
      };
      resetPasswordFun(data);
      // navigate('/login');
      // setIsSuccessfullyChangePassword(true);
    }
  };

  const resendCode = () => {
    setCounter(30);
    sendOtpViaEmail(email);
  };
  return (
    <>
      <div className="forgot-container">
        <div className="container1">
          <div className="logo">
            <img src={LogoMrv} alt="logo" />
          </div>
          <div className="content">
            {!otpSent && !otpVerified && !isSuccessfullyChangePassword ? (
              <>
                <p className="login">Forgot Password</p>
                <p className="text">
                  Enter your credentials to access your account
                </p>
              </>
            ) : (
              <>
                {otpSent && !otpVerified && !isSuccessfullyChangePassword ? (
                  <>
                    <p className="login">Enter OTP</p>
                    <p className="text">
                      We have sent 6 Digit code to <br />
                      your email address
                      <span className="email-text"> {email}</span>
                    </p>
                  </>
                ) : (
                  <>
                    {!isSuccessfullyChangePassword ? (
                      <>
                        <p className="login">Set up new password</p>
                        <p className="text">
                          We have sent 6 Digit code to <br />
                          your email address
                          <span className="email-text"> {email}</span>
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}

            {!otpSent && !otpVerified && !isSuccessfullyChangePassword ? (
              <>
                <div className="Fileds">
                  <div className="UserField">
                    <p className="label-text">Email</p>
                    <div className="">
                      <InputField
                        name="email"
                        type="text"
                        placeholder="Enter email"
                        handleOnChange={(e) => handleChange(e)}
                        value={email}
                      />
                    </div>
                  </div>
                </div>

                {/* <div>00:{formattedSeconds} sec</div> */}
              </>
            ) : (
              <>
                {otpSent && !otpVerified && !isSuccessfullyChangePassword ? (
                  <>
                    <div>
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span> </span>}
                        inputStyle={{
                          width: '41px',
                          height: '40px',
                          margin: '0px 7px',
                          fontSize: '14px',
                          textAlign: 'center',
                          border: '1px solid #E6E6FC',
                          borderRadius: '6px',
                          outline: 'none',
                        }}
                        containerStyle={{ marginTop: '40px' }}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="resend-code-text">
                      <span style={{ width: '21px', display: 'inline-block' }}>
                        00:
                      </span>
                      <span style={{ width: '20px', display: 'inline-block' }}>
                        {formattedSeconds}
                      </span>{' '}
                      sec
                    </div>
                  </>
                ) : (
                  <>
                    {!isSuccessfullyChangePassword ? (
                      <>
                        <div
                          className="PasswordField"
                          style={{ marginTop: '40px' }}
                        >
                          <div className="UserField">
                            <p className="label-text">New Password</p>
                            <div className="">
                              <InputField
                                name="newPassword"
                                type="password"
                                placeholder="Enter new password"
                                handleOnChange={(e) => handleChangePassword(e)}
                                value={password.newPassword}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="PasswordField"
                          style={{ marginTop: '20px' }}
                        >
                          <div className="UserField">
                            <p className="label-text">Confirm Password</p>
                            <div className="">
                              <InputField
                                name="confirmPassword"
                                type="password"
                                placeholder="Enter confirm password"
                                handleOnChange={(e) => handleChangePassword(e)}
                                value={password.confirmPassword}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="success-content">
                          <div>
                            <img src={SuccessFull} alt="Password_Changed" />
                          </div>
                          <div className="success-text">
                            Your Password Has been reset{' '}
                            <span className="email-text">Succesfully</span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {!isSuccessfullyChangePassword && (
              <>
                <div className="button-component">
                  <Button
                    text="Submit"
                    isLoading={isCallApi}
                    handleClick={
                      !otpSent && !otpVerified && !isSuccessfullyChangePassword
                        ? handleSubmitEmail
                        : otpSent &&
                          !otpVerified &&
                          !isSuccessfullyChangePassword
                        ? handleSubmitOtp
                        : handleSubmitPassword
                    }
                  />
                </div>
              </>
            )}

            {otpSent && !otpVerified && !isSuccessfullyChangePassword ? (
              <>
                <div className="code-text">
                  Haven’t received it?{' '}
                  <span
                    className="resend-text"
                    style={{
                      color:
                        formattedSeconds > 0
                          ? 'var(--disable-primary)'
                          : 'var(--color-primary)',
                      cursor: formattedSeconds > 0 ? '' : 'pointer',
                    }}
                    onClick={() => {
                      if (formattedSeconds > 0) {
                      } else {
                        setOtp('')
                        resendCode();
                      }
                    }}
                  >
                    Resend a new code
                  </span>{' '}
                </div>
              </>
            ) : (
              <>
                {otpSent && otpVerified && !isSuccessfullyChangePassword ? (
                  <></>
                ) : (
                  <>
                    <div
                      className="back-button"
                      onClick={() => {
                        setOtpSent(false);
                        setOtpVerified(false);
                        setIsSuccessfullyChangePassword(false);
                        setEmail('');
                        setOtp('');
                        navigate('/login');
                      }}
                    >
                      <div>
                        <img src={BackArrow} alt="Back_Arrow" />
                      </div>
                      <p>Back to Log in</p>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
