import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "../Button/Button";
import { InputField } from "../InputFields/InputField";

const ConfirmNewFarmModal = ({ handleSave,confirmShow, setConfirmShow, allFields,formData}) => {
  return (
    <Modal
      show={confirmShow}
      onHide={() => {
        setConfirmShow(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="custom-confirm-modal">
          <div className="confirm-modal-header">
            <p>Confirm Farm Details</p>
          </div>
          <div className="confirm-modal-body">
            {confirmShow &&
              allFields.map((item) => (
                <>
                  <div className="confirm-modal-container">
                    <div className="label-text">{item.label}</div>
                    <InputField
                      name={item.label}
                      type="text"
                      placeholder={item.label}
                      value={item.type === "search" ? item.values.name : (item.type=="dropdown-tag"?formData.milk_sold_to.join(" , "):item.values)}
                      disable={true}
                    />
                  </div>
                </>
              ))}
          </div>
          <div className="confirm-modal-footer">
          <Button
            text="Submit"
            width="174px"
            handleClick={() => {
                handleSave(formData);
                setConfirmShow(!confirmShow);
            }}
          />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmNewFarmModal;

/*handleSave,
    handleSubmit,
    facilitatorName,
    farmName,
    provinces,
    country,
    signatory,
    pointOfContact,
    city,
    numOfCows,
    farmAddress,
    date,
    farmerName,
    farmerEmail,
    zip,
    milkPlace
    */
