import React, { useState } from 'react';
import './ContactUs.css';
import {
  InputBox,
  InputTextArea,
} from '../../Components/InputFields/InputField';
import 'react-phone-number-input/style.css';
import { Button } from '../../Components/Button/Button';
import PhoneInput from 'react-phone-number-input';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';
import { ContactUsApi } from '../../api/contactus/endpoints';
import Cookies from 'js-cookie';

const ContactUs = () => {
  const userData = JSON.parse(Cookies.get('user_data'));
  const [inputMobileData, setInputMobileData] = useState({
    country_code: '',
    phone_number: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const initialState = [
    {
      label: 'First Name',
      type: 'text',
      value: userData.first_name,
      error: false,
      id: 'first_name',
    },
    {
      label: 'Last Name',
      type: 'text',
      value: userData.last_name,
      error: false,
      id: 'last_name',
    },
    {
      label: 'Email',
      type: 'text',
      value: userData.email,
      error: false,
      id: 'email',
    },
    {
      label: 'Phone Number',
      type: 'phone',
      value: '',
      error: false,
      id: 'phone',
    },
    {
      label: 'Message',
      type: 'address',
      value: '',
      error: false,
      id: 'content',
    },
  ];
  const [details, setDetails] = useState(initialState);

  const handleChange = (name, e, type) => {
    if (type === 'text' || type === 'address') {
      const updatedFields = details.map((field) => {
        if (field.label === name) {
          return { ...field, value: e.target.value, error: false };
        }
        return field;
      });
      setDetails(updatedFields);
    }
  };

  const handlePhoneNumber = (value) => {
    const updatedFields = details.map((field) => {
      if (field.id === 'phone') {
        return { ...field, value: value, error: false };
      }
      return field;
    });
    setDetails(updatedFields);
  };

  const handleSend = async () => {
    let emailError = false;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const updatedFields = details.map((field) => {
      if (field?.value.trim() === '') {
        return { ...field, error: true };
      } else if (field.id === 'email' && !emailPattern.test(field.value)) {
        // Check for email field and validate against emailPattern
        emailError = true;
        return { ...field, error: true };
      }
      return field;
    });
    setDetails(updatedFields);

    const noErrors = updatedFields.every((field) => !field.error);

    if (!noErrors) {
      if (emailError) {
        toastFailure('Invalid Email');
      } else {
        toastFailure('Please Fill all the fields..');
      }
    } else {
      const formData = {};

      details.forEach((field) => {
        if (field.id !== 'phone') {
          formData[field.id] = field.value;
        }
      });
      setIsLoading(true);
      const responseApi = await ContactUsApi(formData);

      if (responseApi.status === 200 || responseApi.status === 201) {
        toastSuccess(responseApi.data.message);
        setIsLoading(false);
        setDetails(initialState);
      } else {
        toastFailure(responseApi.data.message);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      <div className="contact-container">
        <p className="heading-text">
          Please reach out to us if any questions/feedback
        </p>
        <div className="contact-fields">
          {details?.map((items, index) => {
            return (
              <React.Fragment key={index}>
                {items.type === 'phone' ? (
                  <>
                    <div className="conatact-field">
                      <div className="label">
                        {items.label}
                        <span className="required">*</span>
                      </div>
                      <div>
                        <PhoneInput
                          name=""
                          international
                          limitMaxLength
                          style={{
                            border: items.error
                              ? '1px solid #FF2626'
                              : `1px solid #e6e6fc`,
                            marginTop: '6px',
                            height: '50px',
                            borderRadius: '6px',
                            padding: '0px 10px',
                          }}
                          className="custom-phone-input"
                          defaultCountry={'US'}
                          countryCallingCodeEditable={false}
                          value={items.value}
                          onChange={(value) => {
                            handlePhoneNumber(value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {items.type === 'address' ? (
                      <>
                        <div
                          className="conatact-field"
                          style={{ width: '98%' }}
                        >
                          <InputTextArea
                            label={items.label}
                            height="165px"
                            placeholder="Leave us a message..."
                            handleChange={handleChange}
                            type={items.type}
                            value={items.value}
                            isError={items.error}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="conatact-field">
                          <InputBox
                            label={items.label}
                            type={items.type}
                            value={items.value}
                            handleChange={handleChange}
                            isError={items.error}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>

        {/* <div className="checkbox-input">
          <input id="checkbox1" type="checkbox" />
          <label for="checkbox1" className="privacy-text">
            {' '}
            You agree to our friendly{' '}
          </label>
          <span className="privacy"> privacy policy</span>
        </div> */}

        <div className="send-button">
          <Button
            text="Send Message"
            maxWidth="260px"
            handleClick={() => {
              handleSend();
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
