import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from '../api/auth/authSlice';
import projectSlice from '../api/Projects/projectSlice';
import analyticsDataSlice from '../api/analytics/analyticsSlice';
import facilitatorSlice from '../api/facilitator/facilitatorSlice';

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    project: projectSlice,
    analytics: analyticsDataSlice,
    facilitator: facilitatorSlice
  },
});
