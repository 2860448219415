import React, { useState, useRef, useEffect } from 'react';
import './FarmForm.css';
import { Button } from '../../Components/Button/Button';
import { parsePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FormComponent from '../../Components/CommonFormField/FormComponent';
import DeleteIcon from '../../images/Analytics/Group 34070.svg';
import LogoNew from '../../images/Auth/SVG 1.svg';
import AnimalFormComponent from '../../Components/CommonFormField/AnimalFormComponent';
import addIcon from '../../images/Facilitator/AddComponent.svg';
import {
  CheckToken,
  approveStatus,
  createUniqueFarmForm,
  deleteDocument,
  getDataFromUniqueLink,
  getDocumentList,
  uploadDocument,
  getPresignedURL,
} from '../../api/Farm/endpoints';
import { TailSpin } from 'react-loader-spinner';
import Cookies from 'js-cookie';
import Verifyfacilitator from './Verifyfacilitator';
import { useParams, useNavigate } from 'react-router-dom';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';
import RejectModal from '../../Components/Modals/RejectModal';
import {
  percentFat,
  breedList,
  grams_per_list,
  milk_sold_to_list,
  dmi_validation,
} from './constants';
import { useSelector, useDispatch } from 'react-redux';
import { getAccessControl } from '../../api/auth/endpoints';
import { reduxUserPermission } from '../../api/auth/authSlice';

const FarmForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userPermission } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndClose, setIsSaveAndClose] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isUplaodFileLoading, setIsUplaodFileLoading] = useState(false);
  const [isDeletingFileLoading, setIsDeletingFileLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  const [farmFormStatus, setFarmFormStatus] = useState();
  const [isFacilitator, setIsFacilitator] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [otp, setOtp] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);
  const [milkSoldList, setMilkSoldList] = useState(milk_sold_to_list);
  const [forcedStartDate, setForcedStartDate] = useState(null);
  
  const getUserData = async () => {
    const responseUserPermission = await getAccessControl();
    if (responseUserPermission.data.status === 200 || responseUserPermission.data.status === 201) {
      dispatch(reduxUserPermission(responseUserPermission.data.result));
    }
  };

  useEffect(() => {
    if (!userPermission) {
      getUserData();
    }
  }, []);

  const [farmDetails, setFarmDetails] = useState([
    {
      label: 'Name of the Person with Signing Authority [e.g.John Doe]',
      type: 'text',
      value: '',
      error: false,
      id: 'owner_name',
    },
    {
      label: 'Farmer Phone Number',
      type: 'phone',
      value: '',
      error: false,
      id: 'owner_phone',
      state: '',
    },
    {
      label: 'Farmer Email',
      type: 'text',
      value: '',
      error: false,
      id: 'owner_email',
    },
    {
      label: 'Dairy Farm Legal Name',
      type: 'text',
      value: '',
      error: false,
      id: 'farm_name',
    },
    {
      label: 'Facilitator Representative',
      type: 'text',
      value: '',
      error: false,
      id: 'facilitator_name',
    },
    {
      label: 'Facilitator Email',
      type: 'text',
      value: '',
      error: false,
      id: 'facilitator_email',
    },
    {
      label: 'Farm Primary Address',
      type: 'text',
      value: '',
      error: false,
      id: 'address',
    },
    {
      label: 'Country',
      type: 'text',
      value: '',
      error: false,
      id: 'country',
    },
    {
      label: 'State',
      type: 'text',
      value: '',
      error: false,
      id: 'state',
    },
    {
      label: 'City',
      type: 'text',
      value: '',
      error: false,
      id: 'city',
    },
    {
      label: 'Zip Code',
      type: 'text',
      value: '',
      error: false,
      id: 'zip_code',
    },
  ]);
  const initialAnimalInfo = [
    {
      label: 'Breed',
      type: 'dropdown',
      value: '',
      error: false,
      list: breedList,
      id: 'breed',
      notRequired: false,
    },
    {
      label: 'Average Cow Number (Total Lactating)',
      type: 'number',
      value: '',
      error: false,
      id: 'cow_numbers',
      notRequired: false,
    },
    {
      label: 'Average Cow Number on Agolin (Lactating)',
      type: 'number',
      value: '',
      error: false,
      id: 'cow_numbers_on_agolin',
      notRequired: false,
      helpText: 'Average cow numbers on Agolin during the project calendar year by breed (from DIH, DairyComp, or similar with document of record or reference document)'
    },
    {
      label: 'Number of Non-Lactating Animals on Agolin',
      type: 'number',
      value: '',
      error: false,
      id: 'cow_numbers_on_agolin_non_lactating',
      notRequired: false,
    },
    {
      label: 'Agolin Start',
      type: 'date',
      value: forcedStartDate,
      error: false,
      id: 'agolin_start',
      notRequired: false,
    },
    {
      label: 'Agolin End',
      type: 'date',
      value: '',
      error: false,
      id: 'agolin_end',
      notRequired: true,
    },
    {
      label: 'Percent Fat in Diet',
      type: 'dropdown',
      value: '',
      error: false,
      id: 'percent_fat_in_diet',
      list: percentFat,
      notRequired: false,
    },
    {
      label: 'Agolin Grams per Cow per Day',
      type: 'dropdown',
      value: '',
      error: false,
      list: grams_per_list,
      id: 'agolin_grams_per_day',
      notRequired: false,
    },
    {
      label: 'Average Dry Matter intake per Day (lbs)',
      type: 'text',
      value: '',
      error: false,
      id: 'dry_matter_per_day',
      notRequired: false,
    },
    {
      label: 'Milk Sold To',
      type: 'dropdown-tag',
      value: null,
      list: milkSoldList,
      error: false,
      id: 'milk_sold_to',
      notRequired: false,
    },
  ];

  const [animalInfo, setAnimalInfo] = useState([initialAnimalInfo]);
  const [projectInfo, setProjectInfo] = useState({
    form_active_save: false,
    form_active_submit: false,
    farm_form_submission_start: null,
    farm_form_submission_end: null,
  });

  const [farmPartnerInfo, setFarmPartnerInfo] = useState([
    {
      label: 'Nutritionist',
      type: 'text',
      value: '',
      error: false,
      id: 'nutritionist',
    },
    {
      label: 'Feed Mill Contact',
      type: 'phone',
      value: '',
      error: false,
      id: 'feed_mill_contact',
    },
    {
      label: 'Nutritionist Contact',
      type: 'phone',
      value: '',
      error: false,
      id: 'nutritionist_contact',
    },
    {
      label: 'Feed Mill',
      type: 'text',
      value: '',
      error: false,
      id: 'feed_mill',
    },
  ]);
  const [inputMobileData, setInputMobileData] = useState({
    country_code: '',
    phone_number: '',
  });

  const checkToken = async (token) => {
    const responseToken = await CheckToken(token);
    if (responseToken.status === 200 || responseToken.status === 201) {
      setIsAuthenticated(true);
      setIsLoading(false);
      setIsFacilitator(true);
    } else {
      toastFailure(responseToken.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getToken = Cookies.get('auth_token');
    if (getToken) {
      checkToken(getToken);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getDocumentListFun = async (id) => {
    getDocumentList(id)
      .then(async (getDocumentResponse) => {
        if (
          getDocumentResponse.status === 200 ||
          getDocumentResponse.status === 201
        ) {
          setUploadedFile(getDocumentResponse.data.result);
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const handleDownload = async (fileName) => {
    try {
      const url = await getPresignedURL(fileName);
      const ele = document.createElement('a');
      ele.href = url.data.result;
      ele.setAttribute('download', fileName);
      document.body.appendChild(ele);
      ele.click();
      document.body.removeChild(ele);
    } catch (error) {}
  };

  const getFormData = async (id) => {
    getDataFromUniqueLink(id)
      .then((getFormDataResponse) => {
        if (
          getFormDataResponse.status === 200 ||
          getFormDataResponse.status === 201
        ) {
          const farm_info = getFormDataResponse?.data?.result?.farm_information;
          setForcedStartDate(getFormDataResponse?.data?.result?.forced_start_date);
          const farm_partner_info =
            getFormDataResponse?.data?.result?.farm_partner_information;
          const animalInfoData =
            getFormDataResponse?.data?.result?.animal_information;
          setFarmFormStatus(
            getFormDataResponse?.data?.result?.farm_form_status
          );
          setProjectInfo(
            getFormDataResponse?.data?.result?.project_information
          );
          setDocumentList([
            {
              name: getFormDataResponse?.data?.result?.farm_contract_document,
              file: getFormDataResponse?.data?.result?.presigned_url,
            },
          ]);
          if (animalInfoData?.length > 0) {
            let data = [];

            const tempData = animalInfoData.map((item1, index1) => {
              const mappedData = animalInfo.map((item, index) => {
                let data1 = [];
                item.map((property) => {
                  const id = property.id;
                  const value = animalInfoData[index1][id];
                  data1.push({
                    ...property,
                    value: value,
                  });
                });
                data.push(data1);
              });
            });
            setAnimalInfo(data);
          }

          const updatedFarmDetails = farmDetails?.map((detail) => {
            const { id } = detail;
            if (id === 'owner_phone') {
              return {
                ...detail,
                value:
                  farm_info[id] !== null && farm_info[id] !== undefined
                    ? farm_info[id]
                    : '',
                state:
                  farm_info[id] !== null && farm_info[id] !== undefined
                    ? parsePhoneNumber(farm_info[id]).country
                    : '',
              };
            }
            return {
              ...detail,
              value:
                farm_info[id] !== null && farm_info[id] !== undefined
                  ? farm_info[id]
                  : '',
            };
          });
          const updatedFarmPartner = farmPartnerInfo?.map((detail) => {
            const { id } = detail;
            return {
              ...detail,
              value:
                farm_partner_info[id] !== null &&
                farm_partner_info[id] !== undefined
                  ? farm_partner_info[id]
                  : '',
            };
          });
          setFarmPartnerInfo(updatedFarmPartner);
          // Set the updated state
          setFarmDetails(updatedFarmDetails);
          setFirstTimeLoad(false);
        }
      })
      .catch((error) => {
        toastFailure(error.response.data.message);
      });
  };
  useEffect(() => {
    if (id && isAuthenticated) {
      getDocumentListFun(id);
      getFormData(id);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    checkDMIValidation();
    setFirstTimeLoad(true);
  }, [firstTimeLoad]);

  const handleChangeFarm = (name, e, type) => {
    if (type === 'text' || type === 'address') {
      const updatedFields = farmDetails.map((field) => {
        if (field.label === name) {
          return { ...field, value: e.target.value, error: false };
        }
        return field;
      });
      setFarmDetails(updatedFields);
    }
    if (type === 'phone') {
      const updatedFields = farmDetails.map((field) => {
        if (field.label === name) {
          return { ...field, value: e, error: false };
        }
        return field;
      });
      setFarmDetails(updatedFields);
    }
  };
  const AddNewAnimalInfo = () => {
    const updateAnimalInfo = Array.from(animalInfo);
    updateAnimalInfo.push(initialAnimalInfo);
    setAnimalInfo(updateAnimalInfo);
  };

  const RemoveAnimalInfo = (id) => {
    const updateAnimalInfo = Array.from(animalInfo);
    updateAnimalInfo.splice(id, 1);
    setAnimalInfo(updateAnimalInfo);
  };

  const checkValidation = (data) => {
    let flag = false;
    let breed = data[0].value;
    let dmi = parseInt(data[8].value);
    for (let i = 0; i < dmi_validation.length; i++) {
      if (dmi_validation[i].breed === breed) {
        flag = true;
        if (dmi >= dmi_validation[i].min && dmi <= dmi_validation[i].max) {
          return { error: false };
        } else {
          return {
            error: true,
            message: `For ${breed} the typical dmi range is ${dmi_validation[i].min} - ${dmi_validation[i].max}`,
          };
        }
      }
    }
    if (flag === false) return false;
  };

  const checkDMIValidation = () => {
    // This function check s DMI validation for the first time the page loads. This si for facilitators reviewing the data submitted by farmers.
    if (firstTimeLoad) {
      const fieldIndex = 8;
      const tmpFormArray = Array.from(animalInfo);

      for (let formIndex = 0; formIndex < tmpFormArray.length; formIndex++) {
        const tmpFieldArray = JSON?.parse(
          JSON?.stringify(tmpFormArray[formIndex])
        );
        // tmpFieldArray[fieldIndex]["value"] = event.target.value;
        if (tmpFieldArray[8].value) {
          const value = checkValidation(tmpFieldArray);
          value.error
            ? (tmpFieldArray[fieldIndex]['message'] = value.message)
            : delete tmpFieldArray[fieldIndex]['message'];
          tmpFormArray[formIndex] = [...tmpFieldArray];
          setFirstTimeLoad(false);
        }
      }
      setAnimalInfo(tmpFormArray);
    }
  };

  const handleChangeAnimal = (
    fieldName,
    event,
    fieldType,
    formIndex,
    fieldIndex
  ) => {
    if (fieldName === 'Annual Average Dry Matter per Day') {
      const tmpFormArray = Array.from(animalInfo);
      const tmpFieldArray = JSON?.parse(
        JSON?.stringify(tmpFormArray[formIndex])
      );

      tmpFieldArray[fieldIndex]['value'] = event.target.value;
      const value = checkValidation(tmpFieldArray);
      value.error
        ? (tmpFieldArray[fieldIndex]['message'] = value.message)
        : delete tmpFieldArray[fieldIndex]['message'];
      tmpFormArray[formIndex] = [...tmpFieldArray];

      setAnimalInfo(tmpFormArray);
    } else if (fieldName === 'milk_sold_to') {
      const tmpFormArray = Array.from(animalInfo);
      const tmpFieldArray = JSON?.parse(
        JSON?.stringify(tmpFormArray[formIndex])
      );

      tmpFieldArray[fieldIndex]['value'] = event;
      tmpFieldArray[fieldIndex]['error'] = false;

      tmpFormArray[formIndex] = [...tmpFieldArray];
      setAnimalInfo(tmpFormArray);
    } else {
      const { value } = event.target;

      const tmpFormArray = Array.from(animalInfo);
      const tmpFieldArray = JSON.parse(JSON.stringify(tmpFormArray[formIndex]));
      if (
        fieldType === 'text' ||
        fieldType === 'address' ||
        fieldType === 'date' ||
        fieldType === 'number'
      ) {
        tmpFieldArray[fieldIndex]['value'] = value;
        tmpFieldArray[fieldIndex]['error'] = false;
      }
      tmpFormArray[formIndex] = [...tmpFieldArray];
      setAnimalInfo(tmpFormArray);
    }
  };

  const handleSelect = (fieldType, value, formIndex, fieldIndex) => {
    const tmpFormArray = Array.from(animalInfo);
    const tmpFieldArray = JSON.parse(JSON.stringify(tmpFormArray[formIndex]));

    if (fieldType === 'dropdown') {
      tmpFieldArray[fieldIndex]['value'] = value;
      tmpFieldArray[fieldIndex]['error'] = false;
    }

    tmpFormArray[formIndex] = [...tmpFieldArray];
    setAnimalInfo(tmpFormArray);
  };

  const handleChangeFarmPartner = (name, e, type) => {
    if (type === 'text' || type === 'address') {
      const updatedFields = farmPartnerInfo.map((field) => {
        if (field.label === name) {
          return { ...field, value: e.target.value, error: false };
        }
        return field;
      });
      setFarmPartnerInfo(updatedFields);
    }
    if (type === 'phone') {
      const updatedFields = farmPartnerInfo.map((field) => {
        if (field.label === name) {
          return { ...field, value: e, error: false };
        }
        return field;
      });
      setFarmPartnerInfo(updatedFields);
    }
  };

  const uploadFileFunc = async (data) => {
    const responseFileUplaod = await uploadDocument(data);
    if (
      responseFileUplaod.status === 200 ||
      responseFileUplaod.status === 201
    ) {
      const response = getDocumentListFun(id);
      if (response) {
        toastSuccess(responseFileUplaod.data.message);
        setIsUplaodFileLoading(false);
      }
    } else {
      setIsUplaodFileLoading(false);
    }
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef();

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const files = e.target.files[0];
    if (files) {
      setSelectedFiles(files);
      const formData = new FormData();
      formData.append('document', files);
      formData.append('unique_key', id);
      setIsUplaodFileLoading(true);
      uploadFileFunc(formData);
    }
  };

  const handleDeleteFile = async (fileId, object) => {
    setIsDeletingFileLoading(true);
    const formData = new FormData();
    formData.append('id', fileId);
    formData.append('s3_object', object);
    const deleteFileResponse = await deleteDocument(formData);
    const response = getDocumentListFun(id);
    if (response) {
      toastSuccess(deleteFileResponse.data.message);
      setIsDeletingFileLoading(false);
      setCurrDelete(null);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (farmFormStatus !== 'Approved') {
      const getFiles = event.dataTransfer.files[0];

      const formData = new FormData();
      formData.append('document', getFiles);
      formData.append('unique_key', id);
      setIsUplaodFileLoading(true);
      uploadFileFunc(formData);
    }
  };
  const [documentList, setDocumentList] = useState([]);
  const [currDelete, setCurrDelete] = useState(null);

  const submitFarmForm = async (data) => {
    const response = await createUniqueFarmForm(data);
    if (response.status === 200 || response.status === 201) {
      toastSuccess(response.data.message);
      setIsSaveAndClose(false);
      setIsSubmit(false);
      if (data.flag !== 'save') setFarmFormStatus('Facilitator Pending');
      // getFormData(id)
    } else {
      toastFailure(response.data);
      setIsSaveAndClose(false);
      setIsSubmit(false);
    }
  };

  const handleSaveAndClose = (type) => {
    const farmdata = {};
    farmPartnerInfo.forEach((field) => {
      farmdata[field.id] = field.value ? field.value : null;
    });

    const transformedData = animalInfo.map((entry) => {
      const breed = entry.find((item) => item.id === 'breed')?.value || null;
      const cow_numbers = parseFloat(entry.find((item) => item.id === 'cow_numbers')?.value) || null;
      const cow_numbers_on_agolin = parseFloat(entry.find((item) => item.id === 'cow_numbers_on_agolin')?.value) || null;
      const cow_numbers_on_agolin_non_lactating =parseFloat(entry.find((item) => item.id === 'cow_numbers_on_agolin_non_lactating')?.value) || null;
      const agolin_start = entry.find((item) => item.id === 'agolin_start')?.value || null;
      const agolin_end = entry.find((item) => item.id === 'agolin_end')?.value || null;
      const percent_fat_in_diet = entry.find((item) => item.id === 'percent_fat_in_diet')?.value || null;
      const agolin_grams_per_day = entry.find((item) => item.id === 'agolin_grams_per_day')?.value || null;
      const dry_matter_per_day = parseFloat(entry.find((item) => item.id === 'dry_matter_per_day')?.value) || null;
      const milk_sold_to =entry.find((item) => item.id === 'milk_sold_to')?.value?.map((milkItem) => milkItem) || null;

      return {
        breed,
        cow_numbers,
        cow_numbers_on_agolin,
        agolin_start,
        agolin_end,
        dry_matter_per_day,
        percent_fat_in_diet,
        milk_sold_to,
        agolin_grams_per_day,
        cow_numbers_on_agolin_non_lactating,
      };
    });

    // const finalResult = { animal_information: transformedData };
    const animal_information = transformedData;
    const formData = {
      animal_information,
      // farm_information,
      farmdata,
      unique_key: id,
      flag: type,
    };
    if (type === 'save') {
      setIsSaveAndClose(true);
    } else {
      setIsSubmit(true);
    }
    submitFarmForm(formData);
  };

  const handleSubmit = (flag) => {
    let hasError = false;
    if (uploadedFile.length > 0) {
      const updatedData = animalInfo.map((entry) =>
        entry.map((field) => ({
          ...field,
          error:
            field.notRequired === false &&
            (field.value === null ||
              (typeof field.value === 'string' && !field.value.trim())),
        }))
      );
      setAnimalInfo(updatedData);
      hasError = updatedData.some((entry) =>
        entry.some((field) => field.error)
      );
    } else {
      hasError = true;
    }

    if (hasError) {
      toastFailure('Please select all fields...');
      return false;
    } else {
      handleSaveAndClose(flag);
      return true;
    }
  };

  const handleApprove = async () => {
    setIsLoadingApprove(true);
    let valid = handleSubmit('approve');
    if (valid) {
      const data = {
        unique_key: id,
      };
      const responseapprove = await approveStatus(data);
      if (responseapprove.status === 200 || responseapprove.status === 201) {
        setIsLoadingApprove(false);
        setFarmFormStatus('Approved');
        toastSuccess(responseapprove.data.message);
      } else {
        toastFailure(responseapprove.data);
        setIsLoadingApprove(false);
      }
    }
    setIsLoadingApprove(false);
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="spin-body" style={{ height: '95vh', width: '100vw' }}>
            <TailSpin
              height="60px"
              width="60px"
              color="var(--color-primary)"
              ariaLabel="tail-spin-loading"
              radius="1"
              strokeWidth={4}
              visible={true}
            />
          </div>
        </>
      ) : (
        <>
          {isAuthenticated && userPermission ? (
            <>
              <div className="form-container">
                <div className="form-content">
                  <div>
                    <img src={LogoNew} alt="Logo" />
                  </div>
                  {projectInfo.form_active_save === false && (
                    <p className="form-subHeading" style={{ color: 'red' }}>
                      Please note that the window for saving this form is
                      complete. Please contact: support@carboncredify.ai for any
                      questions.
                    </p>
                  )}
                  {projectInfo.form_active_submit === false &&
                    projectInfo.form_active_save === true && (
                      <p className="form-subHeading" style={{ color: 'red' }}>
                        Please note that the window for submitting this farm
                        begins on {projectInfo.farm_form_submission_start}.
                        Until then, you can enter the information and save it,
                        but not submit it for approval. Please contact:
                        support@carboncredify.ai for any questions.
                      </p>
                    )}
                  <div>
                    {/* Farm Information */}
                    <FormComponent
                      heading="Farm Information"
                      list={farmDetails}
                      handleChange={handleChangeFarm}
                      inputMobileData={inputMobileData}
                      setInputMobileData={setInputMobileData}
                      disable={true}
                    />
                    {/* Animal information */}
                    <div>
                      <p className="form-subHeading">Animal Information</p>
                      {animalInfo.map((item, index1) => {
                        return (
                          <React.Fragment key={index1 + 1}>
                            <AnimalFormComponent
                              list={item}
                              handleChange={handleChangeAnimal}
                              inputMobileData={inputMobileData}
                              setInputMobileData={setInputMobileData}
                              AddNewAnimalInfo={AddNewAnimalInfo}
                              index={index1}
                              RemoveAnimalInfo={RemoveAnimalInfo}
                              formDataLength={animalInfo.length}
                              handleSelect={handleSelect}
                              disable={
                                !((farmFormStatus === 'Approved' && userPermission.edit_information_approved) ||
                                  (farmFormStatus !== 'Approved' && userPermission.edit_information_not_approved))
                              }
                            />
                          </React.Fragment>
                        );
                      })}
                      {farmFormStatus !== 'Approved' && (
                        <div className="Add-icon">
                          <img
                            src={addIcon}
                            alt="Add"
                            onClick={() => {
                              if (farmFormStatus !== 'Approved') {
                                AddNewAnimalInfo();
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {/* Farm partner information*/}
                    <FormComponent
                      heading="Farm Partner Information"
                      list={farmPartnerInfo}
                      handleChange={handleChangeFarmPartner}
                      inputMobileData={inputMobileData}
                      setInputMobileData={setInputMobileData}
                      notRequired={true}
                      disable={
                        !((farmFormStatus === 'Approved' &&
                          userPermission.edit_information_approved) ||
                          (farmFormStatus !== 'Approved' &&
                            userPermission.edit_information_not_approved))
                      }
                    />
                    <div className="docuSign-container">
                      <div
                        className="label"
                        style={{ fontSize: '20px', fontWeight: 600 }}
                      >
                        DocuSign Contract<span className="required">*</span>
                      </div>
                      {documentList.length === 0 ? (
                        <>
                          <div className="contract-text">
                            No Contract Available
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="" style={{ marginTop: '30px' }}>
                            {documentList.map((list, index) => {
                              return (
                                <>
                                  <div className="display-list2" key={index}>
                                    {/* <div className="inner-text-date">
                                      {list.date}
                                    </div> */}
                                    <div className="title-text-docusign">
                                      Aggregation Agreement
                                    </div>
                                    <span
                                      className="docusign-view"
                                      onClick={() => {
                                        handleDownload(list.name);
                                      }}
                                    >
                                      View
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </div>
                    {(farmFormStatus === 'Approved' &&
                      userPermission?.upload_files_approved) ||
                      (farmFormStatus !== 'Approved' &&
                        userPermission?.upload_files_not_approved && (
                          <div
                            className="input-form-container"
                            style={{ width: '100%', marginTop: '40px' }}
                          >
                            <div
                              className="label"
                              style={{ fontSize: '20px', fontWeight: 600 }}
                            >
                              Agolin Purchase Receipts
                              <span className="required">*</span>
                            </div>
                            <>
                              <div
                                onDragEnter={handleDragEnter}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onClick={handleUploadClick}
                                className="file-container"
                              >
                                {isUplaodFileLoading ? (
                                  <>
                                    <div>
                                      <TailSpin
                                        height="30px"
                                        width="30px"
                                        color="var(--color-primary)"
                                        ariaLabel="tail-spin-loading"
                                        radius="1"
                                        strokeWidth={5}
                                        visible={true}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="file-content">
                                      <div className="file-text">
                                        Upload Document Here
                                      </div>
                                    </div>
                                  </>
                                )}
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  multiple={true}
                                  onChange={handleFileUpload}
                                  accept="image/png, image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.ms-outlook"
                                />
                              </div>
                            </>
                          </div>
                        ))}

                    <>
                      {uploadedFile?.length !== 0 ? (
                        <>
                          <div className="display-list">
                            <div className="display-list1">
                              <div className="title-text-date">
                                Document Date
                              </div>
                              <div className="title-text">Document Name</div>
                            </div>

                            {uploadedFile?.map((files, index2) => {
                              return (
                                <React.Fragment key={index2}>
                                  <div className="display-list2">
                                    <div className="inner-text-date">
                                      {files.date}
                                    </div>
                                    <div className="inner-text">
                                      {files.name}
                                    </div>
                                    <span
                                      className="docusign-view"
                                      onClick={() => {
                                        handleDownload(files.s3_object);
                                      }}
                                    >
                                      View
                                    </span>
                                    {((farmFormStatus === 'Approved' &&
                                      userPermission.delete_files_approved) ||
                                      (farmFormStatus !== 'Approved' &&
                                        userPermission.delete_files_not_approved)) && (
                                      <div
                                        style={{ cursor: 'pointer' }}
                                        className="delete_icon"
                                        onClick={() => {
                                          handleDeleteFile(files.id, files);
                                          setCurrDelete(index2);
                                        }}
                                      >
                                        {isDeletingFileLoading &&
                                        currDelete === index2 ? (
                                          <TailSpin
                                            key={index2}
                                            height="25px"
                                            width="25px"
                                            color="var(--color-primary)"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            strokeWidth={5}
                                            visible={true}
                                          />
                                        ) : (
                                          <img src={DeleteIcon} alt="" />
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <div className="contract-text">
                          No Document Uploaded
                        </div>
                      )}
                    </>
                    <div className="buttons-end">
                      {((farmFormStatus === 'Farmer Pending' || farmFormStatus === 'Pending' || farmFormStatus === 'Rejected')) && (
                          <>
                            <Button
                              text="Save"
                              width="200px"
                              bgColor="#788B9A"
                              handleClick={() => handleSaveAndClose('save')}
                              isLoading={isSaveAndClose}
                            />
                            {projectInfo.form_active_submit === true && (
                              <Button
                                text="Submit"
                                width="174px"
                                handleClick={() => handleSubmit('submit')}
                                isLoading={isSubmit}
                              />
                            )}
                          </>
                        )
                      }
                      {isFacilitator &&
                        farmFormStatus === 'Facilitator Pending' && (
                          <>
                            <Button
                              text="Reject"
                              width="200px"
                              bgColor="#788B9A"
                              handleClick={() => setIsModalOpen(true)}
                            />
                            <Button
                              text="Save and Approve"
                              width="174px"
                              handleClick={() => handleApprove()}
                              isLoading={isLoadingApprove}
                            />
                          </>
                        )}
                      {!isFacilitator &&
                        farmFormStatus === 'Facilitator Pending' && (
                          <p>Pending on Facilitator</p>
                        )}
                    </div>

                    {farmFormStatus === 'Approved' ? (
                      ''
                    ) : (
                      <div className="condition-text">
                        By submitting, you agree to the&nbsp;
                        <span
                          className="service-text"
                          onClick={() => navigate('/terms-of-services')}
                        >
                          {' '}
                          Terms of Service
                        </span>
                        &nbsp; and&nbsp;
                        <span
                          className="service-text"
                          onClick={() => navigate('/privacy-policy')}
                        >
                          Privacy Policy
                        </span>
                      </div>
                    )}
                    <RejectModal
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      setFarmFormStatus={setFarmFormStatus}
                      uniqueId={id}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Verifyfacilitator
                otp={otp}
                setOtp={setOtp}
                id={id}
                setIsAuthenticated={setIsAuthenticated}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default FarmForm;
