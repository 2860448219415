import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import markerIcon from '../../images/CarbonCredits/markerIcon.svg';
import { defaultcenter } from '../../Pages/CarbonCredits/constants';
import { CarbonCreditsViewData } from '../../api/carbonCredits/endpoints';
const containerStyle = {
  width: '100%',
  height: '380px',
};
function GoogleMapsComp(props) {
  const { initialMP, setInitialMP } = props;
  const fetchMarkerData = async () => {
    if (props.selectedProject !== null && props.clear) {
      const response = await CarbonCreditsViewData(props.selectedProject);
      if (response.status === 200 || response.status === 201) {
        setInitialMP(response.data.farm_locations);
        let updatedCard = props.cardDetails.map((dets) => {
          if (dets.id === 1) {
            return { ...dets, count: response.data.total_farms };
          } else if (dets.id === 2) {
            return { ...dets, count: response.data['total_cows'] };
          } else {
            return { ...dets, count: response.data.total_credits };
          }
        });
        const usCount = response?.data?.farm_locations?.filter((location) =>
          ['united states', 'usa', 'us'].includes(
            location.country.toLowerCase()
          )
        ).length;
        const canadaCount = response.data.farm_locations.filter(
          (location) => location.country.toUpperCase() === 'CANADA'
        ).length;
        if (usCount > canadaCount) props.setCountry('USA');
        else props.setCountry('Canada');
        props.setCardDetails(updatedCard);
      }
    }
  };
  useEffect(() => {
    if (props.selectedProject !== null) fetchMarkerData();
  }, [props.selectedProject, props.clear]);

  return (
    props.isLoaded && (
      <GoogleMap
        id="google-map"
        mapContainerStyle={containerStyle}
        // onLoad={onLoad}
        // onUnmount={onUnmount}
        center={props.center || defaultcenter}
        zoom={3}
        options={props.mapOptions}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {props.markerPoint !== null && (
          <Marker
            position={{
              lat: props.markerPoint.lat,
              lng: props.markerPoint.lng,
            }}
            zIndex={2}
          />
        )}
        {props.farmLocationMarkers &&
          props.farmLocationMarkers.length > 0 &&
          props.farmLocationMarkers.map((marker, index) => (
            <>
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: markerIcon,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                zIndex={parseInt(`-${index}`, 10)}
              />
            </>
          ))}
        {initialMP &&
          initialMP.length > 0 &&
          initialMP.map((MP, index) => (
            <>
              <Marker
                key={index}
                position={{ lat: MP.lat, lng: MP.lng }}
                icon={{
                  url: markerIcon,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                zIndex={parseInt(`-${index}`, 10)}
              />
            </>
          ))}
      </GoogleMap>
    )
  );
}
export default GoogleMapsComp;
