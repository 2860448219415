import React, { useEffect, useState } from "react";
import {
  InputBox,
  InputBoxWithDropdown,
  InputTextArea,
} from "../../Components/InputFields/InputField";
import PhoneInput from "react-phone-number-input";
import SelectEmail from "../InputTag/SelectEmail";
import "./FormComponent.css";
import DeleteIcon from "../../images/Analytics/Group 34070.svg";
import { milk_sold_to_list } from "../../Pages/FarmForm/constants";

const AnimalFormComponent = ({
  list,
  handleChange,
  AddNewAnimalInfo,
  index,
  RemoveAnimalInfo,
  formDataLength,
  handleSelect,
  disable,
  notRequired=false,
}) => {
  const [milkSoldList, setMilkSoldList] = useState(milk_sold_to_list);
  const [selectedValues, setSelectedValues] = useState([]);
  
  useEffect(() => {
    if (list) {
      list.map((item, index) => {
        if (item.id === "milk_sold_to") {
          if (item.value) {
            setSelectedValues(item.value);
          }
        }
      });
    }
  }, [list]);

  useEffect(() => {
    if (selectedValues.length > 0) {
      handleChange("milk_sold_to", selectedValues, "abc", index, 9);
    }
  }, [selectedValues]);

  return (
    <>
      <div className="animal-component">
        <div
          className="form-fields"
          style={{
            padding: "20px",
            background: "#E6E6E6",
            borderRadius: "10px",
          }}
        >
          {list.map((farmInfo, index1) => {
            return (
              <React.Fragment key={index1 + 1}>
                {farmInfo.type === "text" ||
                farmInfo.type === "date" ||
                farmInfo.type === "number" ? (
                  <>
                    <div className="form-field-farm">
                      <InputBox
                        label={farmInfo.label}
                        type={farmInfo.type}
                        handleChange={handleChange}
                        actualIndex={index1}
                        outerIndex={index}
                        value={farmInfo.value}
                        isError={farmInfo.error}
                        disable={disable}
                        notRequired={farmInfo.notRequired}
                        helpText={farmInfo.helpText}
                      />
                      {(index1==8 && farmInfo?.message)&& <span style={{fontWeight:"600",color:"#fabb00"}}>{farmInfo.message}</span>}
                    </div>
                  </>
                ) : (
                  <>
                    {farmInfo.type === "phone" ? (
                      <>
                        <div className="form-field-farm">
                          <div className="label">
                            {farmInfo.label}
                            <span className="required">*</span>
                          </div>
                          <PhoneInput
                            international
                            disabled={disable}
                            limitMaxLength
                            style={{
                              border: `1px solid #e6e6fc`,
                              marginTop: "6px",
                              height: "50px",
                              borderRadius: "6px",
                              padding: "0px 10px",
                            }}
                            defaultCountry={"US"}
                            countryCallingCodeEditable={false}
                            // value={dataObj[itm.Name]}
                            onChange={(value) => {
                              // handleChnagePhone(value, farmInfo.label);
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {farmInfo.type === "dropdown-tag" ? (
                          <>
                            <div className="field-dropdown-tag">
                              <div className="input-box">
                                <div className="label">
                                  {farmInfo.label}
                                  <span className="required">*</span>
                                </div>
                                <div>
                                  <SelectEmail
                                    checked={selectedValues}
                                    setChecked={setSelectedValues}
                                    allOptionsEmail={milkSoldList || []}
                                    setMilkSoldList={setMilkSoldList}
                                    isError={farmInfo.error}
                                    disable={disable}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {farmInfo.type === "dropdown" ? (
                              <>
                                <div className="form-field-farm">
                                  <InputBoxWithDropdown
                                    list={farmInfo.list}
                                    label={farmInfo.label}
                                    value={farmInfo.value}
                                    onSelect={handleSelect}
                                    isError={farmInfo.error}
                                    isForm={true}
                                    actualIndex={index1}
                                    outerIndex={index}
                                    disable={disable}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="form-field-farm">
                                  <InputTextArea
                                    label={farmInfo.label}
                                    height="120px"
                                    type={farmInfo.type}
                                    handleChange={handleChange}
                                    isError={farmInfo.error}
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
        {formDataLength > 1 && (
          <>
            <div
              className="delete-icon-animal"
              onClick={() => {
                if (!disable) {
                  RemoveAnimalInfo(index);
                }
              }}
            >
              <img src={DeleteIcon} alt="delete" />
            </div>
          </>
        )}
      </div>
      {/* {index === 0 && (
        
      )} */}
      {/* <div className="bottom-border-line"></div> */}
    </>
  );
};

export default AnimalFormComponent;
