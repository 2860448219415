import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: null,
  userDetails: null,
  userPermission: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reduxUserData: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    reduxUserDataDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    reduxUserPermission: (state, action) => {
      state.userPermission = action.payload;
    },
  },
});

export const { reduxUserData, reduxUserDataDetails, reduxUserPermission } =
  AuthSlice.actions;
export default AuthSlice.reducer;
