import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import './CommonLayout.css';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';

const CommonLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <>
      <div className="layout-container">
        {/* SIDEBAR CONTAINER */}
        <div
          style={{ width: isSidebarOpen ? '70px' : '240px' }}
          className="sidebar"
        >
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        </div>

        {/* RIGHTSIDE CONTAINER */}
        <div
          className="right-container"
          style={{
            width: isSidebarOpen ? 'calc(100% - 70px)' : 'calc(100% - 240px)',
          }}
        >
          <Navbar />
          <div className="outlet-container">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonLayout;
