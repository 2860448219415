import React, { useEffect, useRef, useState } from "react";
// import Chips from "react-chips/lib/Chips";
// import Chips from "react-chips/lib/Chips";

import Close from "../../images/Facilitator/Close.svg";
import Email from "../../images/Facilitator/SMS.svg";
import CloseChip from "../../images/Facilitator/cancelChip.svg";

import TagsInput from "react-tagsinput";
import "./AddEmailChip.css";

import "react-tagsinput/react-tagsinput.css";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

const RenderTag = (props) => {
  let {
    tag,
    key,
    disabled,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    className,
    ...other
  } = props;

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // const tagValid = useMemo(() => {
  //   return validateEmail(tag)
  // }, [tag])
  return (
    <>
      <span
        key={key}
        {...other}
        className={`${className} ${
          validateEmail(tag)
            ? "!bg-[#E1E4F0] !border-none !text-darkBlue !rounded-[4px]"
            : "!bg-red"
        } relative !pr-[26px] !pl-[10px] !h-[24px]`}
      >
        {getTagDisplayValue(tag)}
        {!disabled && (
          <span onClick={(e) => onRemove(key)}>
            <img
              src={CloseChip}
              alt="close"
              className="w-[16px] h-[16px] cursor-pointer absolute top-[4px] right-[5px]"
            />
          </span>
          // <a className={classNameRemove} onClick={(e) => onRemove(key)} />
        )}
      </span>
      ,
    </>
  );
};

const AddEmailChipModal = ({
  isOpen,
  setIsOpen,
  checked,
  setChecked,
  setAllEmailOptions,
  newtags,
  setNewTags,
  AddValuesToList,
}) => {
  const [email, setEmail] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [isShowError, setIsShowError] = useState(false);
  const [prevEmailInput, setPrevEmailInput] = useState({
    data: "",
    update: false,
  });
  const [newInput, setNewInput] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    if (prevEmailInput.update) {
      setEmailInput(prevEmailInput.data);
      setIsShowError(true);
      setPrevEmailInput((state) => {
        return { ...state, update: false };
      });
    }
  }, [prevEmailInput.update]);

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleChange = (tags) => {
    setNewTags(tags);
    setIsShowError(false);
    setPrevEmailInput((state) => {
      return { ...state, data: "" };
    });
  };

  useEffect(() => {
    setIsValidEmail(validateEmail(email));
  }, [email]);

  const handleAddEmail = () => {
    const isValid = true;
    let isTagValid = true;
    if (newtags?.length > 0) {
      const tagsArray = newtags.reduce((pre, cur) => {
        return pre && validateEmail(cur);
      }, true);
      isTagValid = tagsArray;
    }
    let newarr = Array.from(newtags)
    if(newInput)
      newarr.push(newInput)
    AddValuesToList(newarr,setIsOpen);

    // if (isValid && isTagValid) {
    //   let tmp = Array.from(checked);
    //   if (checked.includes(emailInput)) {
    //     // setErrMsg("Email address already exist");
    //     return;
    //   }
    //   // Continue update state
    //   if (emailInput?.length) {
    //     tmp.push(emailInput);
    //   }

    //   if (tags?.length > 0) {
    //     tmp = [...tmp, ...tags];
    //   }
    //   setChecked(tmp);

    //   let EmailOption = [];
    //   if (tags?.length > 0) {
    //     const emailTagsOption = tags.map((dataE) => {
    //       return { value: dataE, label: dataE };
    //     });
    //     EmailOption = [...EmailOption, ...emailTagsOption];
    //   }

    //   if (emailInput?.length) {
    //     EmailOption.push({ value: emailInput, label: emailInput });
    //   }
    //   setAllEmailOptions((state) => [...state, ...EmailOption]);
    //   setIsOpen(false);
    // } else {
    //   setIsShowError(true);
    // }
  };

  const [chips, setChips] = useState([]);
  const [KEY] = useState({ backspace: 8, tab: 9, enter: 13 });
  const INVALID_CHARS = /[^a-zA-Z0-9 ]/g;


  useEffect(() => {
    setChips([]);
  }, [isOpen]);
  const inputModal = useRef();
  // useOnClickOutside(inputModal?.current?.div, () => setIsOpen(false));

  return (
    <>
      {/* {isOpen && ( */}
      <div
        style={{
          zIndex: 999,
          transform: "translate(-50%,-50%)",
          boxShadow: "0px 10px 30px rgba(60, 66, 87, 0.15)",
          borderRadius: "8px",
          display: isOpen ? "block" : "hidden",
        }}
        className="modal-content1"
      >
        <div className="modal-inner">
          <img
            alt=""
            src={Close}
            className=""
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <div className="modal-newText">Add New</div>
        <TagsInput
          ref={inputModal}
          value={newtags}
          currentValue={emailInput}
          inputValue={emailInput}
          onChangeInput={(e) => {
            setNewInput(e);
            setEmailInput(e);
            setIsShowError(false);

            if (e) {
              setPrevEmailInput((state) => {
                return { ...state, data: e };
              });
            }
          }}
          renderTag={RenderTag}
          onChange={handleChange}
          inputProps={{
            className: "react-tagsinput-input !w-fit ",
            placeholder: "Enter name",
          }}
        />
        {isShowError && (
          <div className="validation-text">
            Please enter a valid email address
          </div>
        )}
        <div className="cancle-modal">
          <div
            className="cancle-modal-content"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </div>
          <div className="add-content" onClick={handleAddEmail}>
            Add
          </div>
        </div>
      </div>
      {/* )}
       */}
    </>
  );
};

export default AddEmailChipModal;
