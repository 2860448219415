import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modals.css';
import { Button } from '../Button/Button';
import { InputBox, InputTextArea } from '../InputFields/InputField';

const ConfirmDeletion = ({
  confirmModal,
  setConfirmModal,
  handleDelete,
  setSelectedForDelete,
  selectedForDelete,
  isLoading,
  setReason,
  reason,
  error,
  setError,
}) => {
  const handleChange = (label, e) => {
    setReason(e.target.value);
    setError(false);
  };
  return (
    <Modal
      show={confirmModal}
      onHide={() => {
        setConfirmModal(false);
        setSelectedForDelete();
        setError(false);
        setReason('');
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="delete-modal"
    >
      <Modal.Body>
        <div
          className=""
          style={{
            padding: '30px',
          }}
        >
          <p className="modal-title-cls">Delete Farm</p>
          {/* <p className="confirm-text">Are you sure want to delete this farm?</p> */}
          <div className="conatact-field" style={{ width: '100%' }}>
            <InputTextArea
              height="125px"
              placeholder="Please mention the reason why you are deleting this farm..."
              label="Reason"
              type="text"
              value={reason}
              handleChange={handleChange}
              isError={error}
            />
          </div>
          <div
            className="cancle-modal-faci"
            style={{ gap: '15px', marginTop: '30px' }}
          >
            <Button
              text="Cancel"
              bgColor="#788B9A"
              handleClick={() => {
                setConfirmModal(false);
                setSelectedForDelete();
                setError(false);
                setReason('');
              }}
            />
            <Button
              text="Delete"
              handleClick={() => {
                handleDelete(selectedForDelete);
              }}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeletion;
