import Cookies from 'js-cookie';
import React from 'react';
import { Navigate } from 'react-router-dom';


const ProtectedRoutes = ({ children, redirectTo }) => {
  const token = () => {
    const getToken = Cookies.get('auth_token');
    return !!getToken ? true : false;
  };

  return token() ? children : <Navigate to={redirectTo} />;
};

export default ProtectedRoutes;
