import axios from 'axios';
import axiosAuthMiddleware from '../middleware/middleware';
import { BASE_URL } from '../config';
import Cookies from 'js-cookie';

export const loginApi = async (data) => {
  const res = axios.post(`${BASE_URL}/login`, data);
  return await res;
};

// Forget Password
export const sendEmailOtp = async (data) => {
  const res = axios.post(`${BASE_URL}/send_otp`, data);
  return await res;
};

export const emailOtpVerification = async (data) => {
  const res = axios.post(`${BASE_URL}/verify_otp`, data);
  return await res;
};

export const resetPassword = async (data) => {
  const res = axios.patch(`${BASE_URL}/reset_password`, data);
  return await res;
};

export const changePassword = async (data) => {
  const url = `/change_password`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'PATCH',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: data,
  });
  return await res;
};

export const getAccessControl = async () => {
  const getToken = Cookies.get('auth_token') || null;

  if (!getToken) {
    const res = axios.get(`${BASE_URL}/user_permission`);
    return await res;
  } else {
    const url = `/user_permission`;

    const res = axiosAuthMiddleware.request({
      url: url,
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    return await res;  
  }
};