import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Button/Button';
import { InputBoxWithDropdown } from '../InputFields/InputField';
import SelectFacilitator from '../InputTag/SelectFacilitator';
import { TailSpin } from 'react-loader-spinner';
import CloseImg from '../../images/Sidebar/CloseIcon (2).png';

const ManageProjectModal = ({
  setModalShow,
  setSelectedValues,
  onHideManageProject,
  allFields,
  modalShow,
  selectedValues,
  allUserList,
  facilitatorDetails,
  isLoadingAddUser,
  isChipEmailModalOpen,
  setIsChipEmailModalOpen,
  handleRemoveFacilitator,
  handleDeletefacilitator,
  handleAddFacilitator,
  handleSelect,
  handleChangeFacilitatorDetails,
  isLoading,
  facilitatorList,
  handleFacilitator,
  getCompanyList,
  companyList,
  handleCompanySelect,
  selectedCompany,
  isLoadingAddFacilitator,
}) => {

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => onHideManageProject()}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="custom-modal-content">
            <div className="modal-title-cls">Manage Project</div>
            <div className="modal-body-content">
              {allFields.map((items, index) => {
                return (
                  <React.Fragment key={index}>
                    {items.type === 'dropdown' ? (
                      <>
                        <div className="" style={{ marginTop: '20px', width: '100%' }}>
                          <InputBoxWithDropdown
                            list={items.list}
                            label={items.label}
                            value={items.values}
                            onSelect={handleSelect}
                            isError={items.error}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className=""
                          style={{ marginTop: '20px', width: '100%' }}
                        >
                          <div className="input-box">
                            <div className="label">
                              {items.label}
                              <span className="required">*</span>
                            </div>
                            <div>
                              <SelectFacilitator
                                checked={selectedValues}
                                setChecked={setSelectedValues}
                                allOptionsEmail={allUserList || []}
                                // setEmails={setEmails}
                                isError={items.error}
                                facilitator_name={true}
                                facilitatorDetails={facilitatorDetails}
                                handleFacilitator={handleFacilitator}
                                handleChangeFacilitatorDetails={
                                  handleChangeFacilitatorDetails
                                }
                                isLoadingAddUser={isLoadingAddUser}
                                isChipEmailModalOpen={isChipEmailModalOpen}
                                setIsChipEmailModalOpen={
                                  setIsChipEmailModalOpen
                                }
                                handleRemoveFacilitator={
                                  handleRemoveFacilitator
                                }
                                getCompanyList={getCompanyList}
                                companyList={companyList}
                                handleCompanySelect={handleCompanySelect}
                                selectedCompany={selectedCompany}
                              />
                            </div>
                          </div>
                          <div
                            className=""
                            style={{
                              height: '170px',
                              marginTop: '16px',
                              padding: '5px 15px',
                              overflowY: 'auto',
                            }}
                          >
                            {isLoading ? (
                              <>
                                <div className="loading-spin">
                                  <TailSpin
                                    height="32px"
                                    width="32px"
                                    color="var(--color-primary)"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    strokeWidth={4}
                                    visible={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {facilitatorList &&
                                facilitatorList.length > 0 ? (
                                  <>
                                    {facilitatorList.map((item, index1) => {
                                      return (
                                        <>
                                          <div className="facli-list">
                                            <div className="fac-name">
                                              {item.facilitator_name}
                                            </div>
                                            <div
                                              className="delete-fac"
                                              onClick={() =>
                                                handleDeletefacilitator(item.id)
                                              }
                                            >
                                              <img src={CloseImg} alt="close" />
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            className="cancle-modal-faci"
                            style={{ gap: '15px' }}
                          >
                            <div
                              className="cancle-modal-content"
                              style={{
                                width: '50%',
                                borderRadius: '6px',
                                background: '#788B9A',
                                color: 'white',
                                height: '50px',
                              }}
                              onClick={() => {
                                onHideManageProject();
                              }}
                            >
                              Cancel
                            </div>
                            {isLoadingAddFacilitator ? (
                              <>
                                <div
                                  className="add-content"
                                  style={{
                                    width: '50%',
                                    borderRadius: '6px',
                                    background: 'var(--color-primary)',
                                    height: '50px',
                                    cursor:'unset !important'
                                  }}
                                >
                                  <TailSpin
                                    height="25px"
                                    width="25px"
                                    color="var(--color-primary)"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    strokeWidth={4}
                                    visible={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="add-content"
                                  style={{
                                    width: '50%',
                                    borderRadius: '6px',
                                    background: 'var(--color-primary)',
                                    height: '50px',
                                  }}
                                  onClick={handleAddFacilitator}
                                >
                                  Add
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageProjectModal