import React, { useEffect, useRef, useState } from 'react';
import './Navbar.css';
import ProjectImg from '../../images/Projects/Group 33971.png';
import DownArrow from '../../images/Sidebar/Vector (1).svg';
import ArrowRight from '../../images/Projects/Right (1).png';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { useLocation } from 'react-router-dom';
import { useProjectList } from '../../api/Projects/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  reduxProjectList,
  reduxSelectedProject,
} from '../../api/Projects/projectSlice';
import { TailSpin } from 'react-loader-spinner';
import { getProjectList } from '../../api/Projects/endpoints';

const Navbar = () => {
  const projectDialogRef = useRef();
  const dispatch = useDispatch();
  const { projectList, selectedProject } = useSelector(
    (state) => state.project
  );
  const { userPermission } = useSelector((state) => state.auth);

  const [openProjectList, setOpenProjectList] = useState(false);
  useOnClickOutside(projectDialogRef, () => setOpenProjectList(false));
  const [hoverIndex, setHoverIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [allProjectList, setAllProjectList] = useState(null);

  // API CALLING
  // const { data: allProjectList, isLoading, error } = useProjectList();
  useEffect(() => {
    setIsLoading(true);
    const response = async () => {
      let res = await getProjectList();
      if (res.status === 200 || res.status === 201) {
        setIsLoading(false);
        setAllProjectList(res?.data?.result);
        dispatch(reduxProjectList(res?.data?.result));
        let selectedProject_received = res?.data?.result?.filter(
          (item) =>
            item.id.toString() === localStorage.getItem('selectedProject')
        );
        if (selectedProject_received?.length === 1) {
          dispatch(reduxSelectedProject(selectedProject_received[0]));
        } else {
          dispatch(reduxSelectedProject(res?.data?.result[0]));
        }
      } else {
        setIsLoading(false);
      }
    };
    response();
  }, []);

  const location = useLocation();

  const selectProject = (id) => {
    const getAllProject = [...allProjectList];
    const selectedProject = getAllProject.find((item) => item.id === id);
    localStorage.setItem('selectedProject', selectedProject.id);
    dispatch(reduxSelectedProject(selectedProject));
    setOpenProjectList(false);
  };
  return (
    <>
      <div className="navbar-container">
        <p className="header-text">
          {location.pathname === '/new-farm'
            ? 'Add New Farm'
            : location.pathname === '/farm-portfolio'
            ? 'Farm Portfolio'
            : location.pathname === '/analytics'
            ? 'Analytics'
            : location.pathname === '/carboncredits'
            ? 'Carbon Credits'
            : 'Contact Us'}
        </p>
        {userPermission && userPermission.project_list === 'assigned only' && (
          <div className="project-select" ref={projectDialogRef}>
            {isLoading ? (
              <>
                <div className="loading-spin">
                  <TailSpin
                    height="32px"
                    width="32px"
                    color="var(--color-primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    strokeWidth={4}
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="project-image">
                  <div
                    className=""
                    style={{
                      width: '20px',
                      display: 'flex',
                      fontSize: '20px',
                      textTransform: 'uppercase',
                    }}
                  >
                    {selectedProject?.name.charAt(0)}
                  </div>
                </div>
                <div className="project-content">
                  <p>{selectedProject?.name}</p>
                  <p>{selectedProject?.year}</p>
                </div>
                <div
                  className="down-arrow"
                  onClick={() => setOpenProjectList(!openProjectList)}
                >
                  <img
                    src={DownArrow}
                    alt="arrow_img"
                    className="arrow_img"
                    style={{
                      transform: openProjectList
                        ? 'rotate(180deg)'
                        : 'rotate(360deg)',
                    }}
                  />
                </div>
              </>
            )}

            {openProjectList && (
              <>
                <div className="project-list-dialog">
                  {allProjectList?.map((project, index1) => {
                    return (
                      <React.Fragment key={index1}>
                        <div
                          className="project-selects"
                          onMouseLeave={() => setHoverIndex()}
                          onMouseEnter={() => setHoverIndex(index1)}
                        >
                          <div className="project-image">
                            <div
                              className=""
                              style={{
                                width: '20px',
                                display: 'flex',
                                fontSize: '20px',
                                textTransform: 'uppercase',
                              }}
                            >
                              {project.name.charAt(0)}
                            </div>
                          </div>
                          <div className="project-content">
                            <p>{project.name}</p>
                            <p>{project.year}</p>
                          </div>
                          <div
                            className="open-text"
                            onClick={() => selectProject(project.id)}
                          >
                            {index1 === hoverIndex && <>Open</>}
                          </div>
                          <div className="down-arrow-list">
                            <img src={ArrowRight} alt="arrow_img" />
                          </div>
                        </div>
                        <div className="border-line"></div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
