import { useMutation } from 'react-query';
import {emailOtpVerification, loginApi, resetPassword, sendEmailOtp} from './endpoints';


// LOGIN
export const useLogin = () => {
  return useMutation((data) => loginApi(data));
};

// FORGET PASSWORD
export const useSendEmailOtp = () => {
  return useMutation((data) => sendEmailOtp(data));
};

export const useEmailOtpVarification = () => {
  return useMutation((data) => emailOtpVerification(data));
};

export const useResetPassword = () => {
  return useMutation((data) => resetPassword(data));
};
