import { createSlice } from '@reduxjs/toolkit';
import FarmImg from '../../images/Analytics/FarmImg.svg';
import CowImg from '../../images/Analytics/CowImg.svg';
import CarbonImg from '../../images/Analytics/Co2.svg';

const initialState = {
  analyticsDataCounts: [
    {
      title: 'Total farms',
      count: null,
      image: FarmImg,
      key: 'total farm',
    },
    {
      title: 'Total Cows',
      count: null,
      image: CowImg,
      key: 'total_cows',
    },
    {
      title: 'Total Carbon Credits',
      count: null,
      image: CarbonImg,
      key: 'total_credit',
    },
  ],
};

export const analyticsDataSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    reduxAnalyticsData: (state, action) => {
      state.analyticsDataCounts = action.payload;
    },
  },
});

export const { reduxAnalyticsData } = analyticsDataSlice.actions;
export default analyticsDataSlice.reducer;
