import React, { useEffect, useRef, useState } from 'react';
import './Sidebar.css';
import LogoNew from '../../images/Auth/SVG 1.svg';
import SideIconOpen from '../../images/Sidebar/SideOpen.png';
import SideIconClose from '../../images/Sidebar/SideClose.png';
import LogoIcon from '../../images/Auth/Layer 2.svg';
import FarmIcon from '../../images/Sidebar/FarmIcon.svg';
import ActiveFarmIcon from '../../images/Sidebar/ActiveFarm.svg';
import FacilatorIcon from '../../images/Sidebar/Facilator.svg';
import ActiveFacilatorIcon from '../../images/Sidebar/ActiveFacilator.svg';
import ContactUsIcon from '../../images/Sidebar/ContactUs.svg';
import ActiveContactUsIcon from '../../images/Sidebar/ActiveContactUs.svg';
import dots from '../../images/Sidebar/dots.png';
import UserImg from '../../images/Sidebar/User.svg';
import SettingImg from '../../images/Sidebar/Group 1.svg';
import LogOutImg from '../../images/Sidebar/Logout.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { getAllUserList } from '../../api/Farm/endpoints';
import { getAllProjects } from '../../api/Projects/endpoints';
import { reduxAllProjectList } from '../../api/Projects/projectSlice';
import {
  addUser,
  checkEmailExist,
  createFacilitator,
  deleteFacilitator,
  fetchCompanyList,
  getFacilitatorListAll,
} from '../../api/facilitator/endpoints';

import { toastFailure, toastSuccess } from '../Toaster/Toast';
import ProfileModal from '../Modals/ProfileModal';
import ManageProjectModal from '../Modals/ManageProjectModal';
import { changePassword, getAccessControl } from '../../api/auth/endpoints';
import { reduxUserPermission } from '../../api/auth/authSlice';

let facilitatorList;
let allUserList;

let error;
const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const [menuOptions, setmenuOptions] = useState([
    {
      text: 'Farm Portfolio',
      link: '/farm-portfolio',
      Icon: FacilatorIcon,
      activeIcon: ActiveFacilatorIcon,
    },
    {
      text: 'Contact us',
      link: '/contact-us',
      Icon: ContactUsIcon,
      activeIcon: ActiveContactUsIcon,
    },
  ]);
  const { projectList, allProjectList } = useSelector((state) => state.project);
  const { selectedProject } = useSelector((state) => state.project);
  const { userPermission } = useSelector((state) => state.auth);
  const profileDialogRef = useRef();
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [myProfileShow, setMyProfileShow] = React.useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddFacilitator, setIsLoadingAddFacilitator] = useState(false);
  const [isLoadingAddUser, setIsLoadingAddUser] = useState(false);
  useOnClickOutside(profileDialogRef, () => setOpenProfileModal(false));
  const [logoSrc, setLogoSrc] = useState(!isSidebarOpen ? LogoNew : LogoIcon);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isChipEmailModalOpen, setIsChipEmailModalOpen] = useState(false);
  const userData = JSON.parse(Cookies.get('user_data'));
  const [selectedProjectFromList, setSelectedProjectFromList] = useState();

  const getUserDATA = async () => {
    const responseUserPermission = await getAccessControl();
    if (responseUserPermission.data.status === 200 || responseUserPermission.data.status === 201) {
      dispatch(reduxUserPermission(responseUserPermission.data.result));
    }
  };

  useEffect(() => {
    if (!userPermission) {
      getUserDATA();
    }
  }, []);

  const initialState = [
    {
      label: 'Project Name',
      type: 'dropdown',
      values: '',
      error: false,
      id: 'project_name',
      list: allProjectList,
    },
    {
      label: 'Facilitator Name',
      type: 'search',
      values: {
        name: '',
        id: null,
      },
      error: false,
      id: 'facilitator',
      list: facilitatorList,
    },
  ];

  const initialFacilitatorDetails = [
    {
      label: 'First name',
      type: 'text',
      values: '',
      error: false,
      id: 'first_name',
    },
    {
      label: 'Last name',
      type: 'text',
      values: '',
      error: false,
      id: 'last_name',
    },
    { label: 'Email', type: 'text', values: '', error: false, id: 'email' },
  ];

  const [facilitatorDetails, setFacilitatorDetails] = useState(
    initialFacilitatorDetails
  );
  const [companyList, setCompanyList] = useState();
  const [selectedCompany, setSelectedCompany] = useState('');
  const [storeUserData, setStoreUserData] = useState();
  const [storeMultipleUser, setStoreMultipleuser] = useState([]);
  const initialResetPassword = {
    old_password: '',
    new_password: '',
    confirm_password: '',
  };
  const [allFields, setAllFields] = useState(initialState);
  const [changePassClicked, setChangePassClicked] = useState(false);
  const [resetPasswordFields, setResetPasswordfields] =
    useState(initialResetPassword);
    useEffect(() => {
    setmenuOptions((currentOptions) => {
      let updatedOptions = [
        {
          text: 'Farm Portfolio',
          link: '/farm-portfolio',
          Icon: FacilatorIcon,
          activeIcon: ActiveFacilatorIcon,
        },
      ];

      if (userPermission?.farm_access) {
        const newFarmOption = {
          text: 'Add New Farm',
          link: '/new-farm',
          Icon: FarmIcon,
          activeIcon: ActiveFarmIcon,
        };
        updatedOptions.push(newFarmOption);
      } 
      if (userPermission?.credits_analytics_access) {
        const carbonCreditsOption = {
          text: 'Carbon Credits',
          link: '/carboncredits',
          Icon: ContactUsIcon,
          activeIcon: ActiveContactUsIcon,
        };
        updatedOptions.push(carbonCreditsOption);
      }

      const contactUsOption = {
        text: 'Contact us',
        link: '/contact-us',
        Icon: ContactUsIcon,
        activeIcon: ActiveContactUsIcon,
      };
      updatedOptions.push(contactUsOption);

      return updatedOptions;
    });
  }, [userPermission]);
  useEffect(() => {
    if (allProjectList) {
      const updatedFields = allFields.map((field) => {
        if (field.id === 'project_name') {
          return { ...field, list: allProjectList };
        }
        return field;
      });
      setAllFields(updatedFields);
    }
  }, [allProjectList]);

  // Update the image source when isSidebarOpen changes
  useEffect(() => {
    setLogoSrc(!isSidebarOpen ? LogoNew : LogoIcon);
  }, [isSidebarOpen]);

  // Add a CSS class for the transition
  const imageTransitionClass = isSidebarOpen
    ? 'open-transition'
    : 'close-transition';

  const navigate = useNavigate();
  const responseAllProject = async () => {
    const response = await getAllProjects();
    if (response.status === 200 || response.status === 201) {
      dispatch(reduxAllProjectList(response.data.result));
      setIsSearchLoading(false);
    } else {
      setIsSearchLoading(false);
    }
  };
  const responseSearch = async (id) => {
    setIsLoading(true);
    let res = await getFacilitatorListAll(id);
    if (res.status === 200 || res.status === 201) {
      facilitatorList = res.data.result;
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const responseUserList = async () => {
    let res = await getAllUserList();
    if (res.status === 200 || res.status === 201) {
      allUserList = res.data.result;
      setIsSearchLoading(false);
    } else {
      setIsSearchLoading(false);
    }
  };

  const handleSelect = (name, value) => {
    error = false;
    const updatedFields = allFields.map((field) => {
      if (field.label === name) {
        return { ...field, values: value, error: false };
      }
      return field;
    });
    setAllFields(updatedFields);

    let projectId1;
    allProjectList.map((project) => {
      if (project.name === value) {
        projectId1 = project.id;
      }
    });
    setSelectedProjectFromList(projectId1);
    responseSearch(projectId1);
  };

  const getCompanyList = async () => {
    const responseCompany = await fetchCompanyList();
    if (responseCompany.status === 200 || responseCompany.status === 201) {
      setCompanyList(responseCompany.data.result);
    }
  };
  const handleCompanySelect = (lable, name, object) => {
    setSelectedCompany(object);
  };
  const handleChangeFacilitatorDetails = (name, e, type) => {
    const updatedFields = facilitatorDetails.map((field) => {
      if (field.label === name) {
        return { ...field, values: e.target.value, error: false };
      }
      return field;
    });
    setFacilitatorDetails(updatedFields);
  };

  const handleAddFacilitator = async () => {
    setIsLoadingAddFacilitator(true);
    let isWithoutId;
    let projectName;
    let projectId;
    allFields.map((item) => {
      if (item.id === 'project_name') {
        if (item.values === '') {
          toastFailure('Select a project first');
          error = true;
          setIsLoadingAddFacilitator(false);
          return;
        } else {
          // projectId
          projectName = item.values;
        }
      } else if (selectedValues.length < 1) {
        toastFailure('Select facilitor');
        error = true;
        setIsLoadingAddFacilitator(false);
        return;
      }
    });
    allProjectList.map((project) => {
      if (project.name === projectName) {
        projectId = project.id;
      }
    });

    if (!error) {
      function isFullNamePresent(array1, item) {
        const fullNameToCheck = `${item.first_name} ${item.last_name}`;
        return array1.includes(fullNameToCheck);
      }

      let matchFound = false;

      // Check each item in array2
      for (const item of storeMultipleUser) {
        if (isFullNamePresent(selectedValues, item)) {
          matchFound = true;
          break; // Stop the loop
        }
      }

      // If no match is found
      if (matchFound) {
        const formData = {
          user_details: storeMultipleUser,
          project_name: projectName,
        };

        // setIsLoadingAddUser(true);
        const response = await addUser(formData);
        if (response.status === 201 || response.status === 200) {
          toastSuccess(response.data.message);
          let res = await getAllUserList();
          if (res.status === 200 || res.status === 201) {
            allUserList = res.data.result;

            const nameIdMap = {};

            // Populate the nameIdMap with values from firstObj
            allUserList.forEach((obj) => {
              const fullName = `${obj.first_name} ${obj.last_name}`;
              nameIdMap[fullName] = obj.id;
            });

            // Iterate over secondObj and match with nameIdMap
            const matchedIds = selectedValues.map((name) => nameIdMap[name]);

            const data = {
              project_id: projectId,
              user_id: matchedIds,
            };
            const responsefaclitator = await createFacilitator(data);
            if (
              responsefaclitator.status === 200 ||
              responsefaclitator.status === 201
            ) {
              setModalShow(false);
              toastSuccess(responsefaclitator.data.message);
              setSelectedValues([]);
              setIsLoadingAddFacilitator(false);
            } else {
              toastFailure(responsefaclitator.data.message);
              setIsLoadingAddFacilitator(false);
            }
          } else {
          }
        } else {
          toastFailure(response.data.message);
          setIsLoadingAddFacilitator(false);
        }
      }

      //if Not new user added
      else {
        const nameIdMap = {};

        allUserList.forEach((obj) => {
          const fullName = `${obj.first_name} ${obj.last_name}`;
          nameIdMap[fullName] = obj.id;
        });

        const matchedIds = selectedValues.map((name) => nameIdMap[name]);

        const data = {
          project_id: projectId,
          user_id: matchedIds,
        };
        const responsefaclitatoer = await createFacilitator(data);
        if (
          responsefaclitatoer.status === 200 ||
          responsefaclitatoer.status === 201
        ) {
          setModalShow(false);
          toastSuccess(responsefaclitatoer.data.message);
          setSelectedValues([]);
          setIsLoadingAddFacilitator(false);
        } else {
          toastFailure(responsefaclitatoer.data.message);
          setIsLoadingAddFacilitator(false);
        }
      }

      // const response = await createFacilitator(data);
      // if (response.status === 200 || response.status === 201) {
      //   setModalShow(false);
      //   toastSuccess(response.data.message);
      //   setSelectedValues([]);
      // } else {
      //   toastFailure(response.data.message);
      // }
    }
    setStoreMultipleuser([]);
    window.location.reload();
  };

  const handleFacilitator = async () => {
    setIsLoadingAddUser(true);
    let emailError = false;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const updatedFields = facilitatorDetails.map((field) => {
      if (field.values.trim() === '') {
        return { ...field, error: true };
      } else if (field.id === 'email' && !emailPattern.test(field.values)) {
        // Check for email field and validate against emailPattern
        emailError = true;
        return { ...field, error: true };
      }
      return field;
    });
    setFacilitatorDetails(updatedFields);

    const noErrors = updatedFields.every((field) => !field.error);
    if (!noErrors) {
      if (emailError) {
        toastFailure('Invalid Email');
        setIsLoadingAddUser(false);
      } else {
        toastFailure('Please Fill all the fields..');
        setIsLoadingAddUser(false);
      }
    } else {
      if (!selectedCompany) {
        toastFailure('Select a Company');
        setIsLoadingAddUser(false);
      } else {
        let emailValue;

        facilitatorDetails.map((item) => {
          if (item.id === 'email') {
            emailValue = item.values;
          }
        });
        const emailData = {
          email: emailValue,
        };

        const formData = {};
        const responseEmail = await checkEmailExist(emailData);
        if (responseEmail.status === 200 || responseEmail.status === 201) {
          const tempAllUser = [...storeMultipleUser];
          facilitatorDetails.forEach((field) => {
            formData[field.id] = field.values;
            formData['company_id'] = selectedCompany.id;
            formData['company'] = selectedCompany.company_name;
          });
          setIsLoadingAddUser(false);
          setStoreUserData(formData);
          tempAllUser.push(formData);
          setStoreMultipleuser(tempAllUser);
          allUserList.push(formData);
          setSelectedValues([
            ...selectedValues,
            facilitatorDetails[0].values + ' ' + facilitatorDetails[1].values,
          ]);
          setIsChipEmailModalOpen(false);
          setFacilitatorDetails(initialFacilitatorDetails);
          setSelectedCompany();
        } else {
          toastFailure(responseEmail.data);
          setIsLoadingAddUser(false);
        }
      }
    }
  };

  const handleDeletefacilitator = async (id) => {
    const data = {
      id: id,
    };
    const responseDelete = await deleteFacilitator(data);
    if (responseDelete.status === 200 || responseDelete.status === 201) {
      responseSearch(selectedProjectFromList);
      toastSuccess(responseDelete.data.message);
    } else {
      toastFailure(responseDelete.data.message);
    }
  };

  const handleRemoveFacilitator = () => {
    setFacilitatorDetails(initialFacilitatorDetails);
  };

  const ChangePasswordClicked = () => {
    setChangePassClicked(true);
  };

  const onHideManageProject = () => {
    setModalShow(false);
    setSelectedValues([]);
    setAllFields(initialState);
    //
    facilitatorList = [];
    setAllFields(initialState);
    setFacilitatorDetails(initialFacilitatorDetails);
    setStoreMultipleuser([]);
    window.location.reload();
  };

  const handleChangePassword = (e) => {
    const { name, value } = e.target;
    setResetPasswordfields({
      ...resetPasswordFields,
      [name]: value.toString().trim(),
    });
  };

  const resetPasswordFun = async () => {
    setIsLoading(true);
    if (
      resetPasswordFields.new_password !== resetPasswordFields.confirm_password
    ) {
      toastFailure('Password not matched');
      setIsLoading(false);
    } else if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
        resetPasswordFields.new_password
      ) === false
    ) {
      setIsLoading(false);
      toastFailure(
        'Password must contain at least one small and capital letter,special characters and numbers.'
      );
    } else {
      const formData = {
        email: userData.email,
        old_password: resetPasswordFields.old_password,
        new_password: resetPasswordFields.new_password,
      };

      const response = await changePassword(formData);
      if (response.data.status === 200 || response.data.status === 201) {
        setIsLoading(false);
        toastSuccess(response.data.message);
        setChangePassClicked(false);
        setMyProfileShow(false);
      } else {
        setIsLoading(false);
        toastFailure(response.data);
      }
      // setIsLoading(false);
    }
  };

  const handleRemoveFields = () => {
    setResetPasswordfields(initialResetPassword);
  };

  return (
    <div className="sidebar-container">
      <div className={`logo-container ${imageTransitionClass}`}>
        <img src={logoSrc} alt="Logo" />
      </div>
      <div className="borderdiv">
        <div
          className="sidebar-icon"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <img
            src={!isSidebarOpen ? SideIconOpen : SideIconClose}
            alt="SidebarIcon"
          />
        </div>
      </div>

      <div className="menu-container">
        {/* Sidebar Menus */}
        <div className="sideMenus">
          {menuOptions.map((menus, index) => {
            return (
              <React.Fragment key={index}>
                <NavLink className="navlinkbase" to={menus.link}>
                  {({ isActive }) => (
                    <>
                      <div className={`menu-icons`}>
                        <img
                          src={isActive ? menus.activeIcon : menus.Icon}
                          alt={menus.text}
                        />
                      </div>
                      {!isSidebarOpen && (
                        <>
                          <div
                            className="menu-text"
                            style={{
                              color: isActive ? '#3BB14A' : '#B1B6CF',
                              fontWeight: isActive ? 600 : 400,
                              // opacity : isSidebarOpen ? 0 :1,
                            }}
                          >
                            {menus.text}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </NavLink>
              </React.Fragment>
            );
          })}
        </div>

        {/* Profile Setting */}
        <div
          className="profile-container"
          style={{ margin: !isSidebarOpen ? '24px 24px' : '24px 8px' }}
        >
          <div className="user-profile" ref={profileDialogRef}>
            <div
              className="menu-icons"
              style={{ width: !isSidebarOpen ? '' : '100%' }}
              onClick={() => setOpenProfileModal(!openProfileModal)}
            >
              {/* <img src={UserImage} alt="user_image" /> */}
              <div
                className=""
                style={{
                  width: '20px',
                  display: 'flex',
                  fontSize: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                {userData.first_name.charAt(0)}
              </div>
            </div>
            {!isSidebarOpen && (
              <div
                className="profile"
                onClick={() => setOpenProfileModal(!openProfileModal)}
              >
                <div>
                  <div className="userName">
                    {userData.first_name + ' ' + userData.last_name}
                  </div>
                  {/* <div className="user-role">Admin</div> */}
                </div>
                <div className="dots">
                  <img src={dots} alt="options" />
                </div>
              </div>
            )}

            {openProfileModal && (
              <>
                <div className="dialog">
                  {userPermission?.manage_project_access && (
                    <>
                      <div
                        className="dialog-list"
                        onClick={() => {
                          setModalShow(!modalShow);
                          responseUserList();
                          // responseSearch();
                          responseAllProject();
                        }}
                      >
                        <div className="menu-icons">
                          <img src={SettingImg} alt="user_img" />
                        </div>
                        <div className="menu-text">Manage Project</div>
                      </div>
                    </>
                  )}
                  <div
                    className="dialog-list"
                    style={{
                      marginTop: [
                        'Aggregator Admin',
                        'Admin',
                        'Facilitator Lead',
                      ].includes(userData.role)
                        ? '20px'
                        : '0px',
                    }}
                    onClick={() => setMyProfileShow(!myProfileShow)}
                  >
                    <div className="menu-icons">
                      <img src={UserImg} alt="user_img" />
                    </div>
                    <div className="menu-text">My Profile</div>
                  </div>
                  <div
                    className="dialog-list"
                    style={{ marginTop: '20px' }}
                    onClick={() => {
                      navigate('/login');
                      Cookies.remove('auth_token');
                    }}
                  >
                    <div className="menu-icons">
                      <img src={LogOutImg} alt="logout_img" />
                    </div>
                    <div className="menu-text">Log out</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <ManageProjectModal
          setModalShow={setModalShow}
          setSelectedValues={setSelectedValues}
          onHideManageProject={onHideManageProject}
          allFields={allFields}
          modalShow={modalShow}
          selectedValues={selectedValues}
          allUserList={allUserList}
          facilitatorDetails={facilitatorDetails}
          isLoadingAddUser={isLoadingAddUser}
          isChipEmailModalOpen={isChipEmailModalOpen}
          setIsChipEmailModalOpen={setIsChipEmailModalOpen}
          handleRemoveFacilitator={handleRemoveFacilitator}
          handleDeletefacilitator={handleDeletefacilitator}
          handleAddFacilitator={handleAddFacilitator}
          handleSelect={handleSelect}
          handleChangeFacilitatorDetails={handleChangeFacilitatorDetails}
          isLoading={isLoading}
          facilitatorList={facilitatorList}
          handleFacilitator={handleFacilitator}
          getCompanyList={getCompanyList}
          companyList={companyList}
          handleCompanySelect={handleCompanySelect}
          selectedCompany={selectedCompany}
          isLoadingAddFacilitator={isLoadingAddFacilitator}
        />

        <ProfileModal
          setMyProfileShow={setMyProfileShow}
          myProfileShow={myProfileShow}
          userData={userData}
          ChangePasswordClicked={ChangePasswordClicked}
          changePassClicked={changePassClicked}
          resetPasswordFields={resetPasswordFields}
          handleChangePassword={handleChangePassword}
          setChangePassClicked={setChangePassClicked}
          resetPasswordFun={resetPasswordFun}
          isLoading={isLoading}
          handleRemoveFields={handleRemoveFields}
        />
      </div>
    </div>
  );
};

export default Sidebar;
