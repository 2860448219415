import React from 'react';
import { toast } from 'react-toastify';
import ErrorIcon  from '../../images/Auth/ErrorIcon.svg';
import SuccessIcon  from '../../images/Auth/Group 34069.svg';
import './Toast.css';

const defaultArguments = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  icon: false,
};

export const toastSuccess = (content) => {
  toast.error(
    <>
      <div className="toast-success">
        <div className="lineSuccess"></div>
        <div className="error-icon">
          <img
            src={SuccessIcon}
            alt="error-img"
            style={{ width: '100%', height: '100%' }}
          />
        </div>
        <div className="content-text">{content}</div>
      </div>
    </>,
    {
      ...defaultArguments,
      // className: 'toast-error',
      // icon: () => <ErrorIcon />,
    }
  );
};

export const toastFailure = (content) => {
  toast.error(
    <>
      <div className="toast-error">
        <div className="line"></div>
        <div className="error-icon">
          <img src={ErrorIcon} alt="error-img" style={{width:'100%',height:'100%'}} />
        </div>
        <div className="content-text">{content}</div>
      </div>
    </>,
    {
      ...defaultArguments,
      // className: 'toast-error',
      // icon: () => <ErrorIcon />,
    }
  );
};
