import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './CreateFarm.css';
import {
  InputAutoAddress,
  InputBox,
  InputBoxWithDropdown,
  SearchInputBox,
} from '../../Components/InputFields/InputField';
import { Button } from '../../Components/Button/Button';
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';
import { useAddNewFarm } from '../../api/Farm/hooks';
import { getFacilitatorList } from '../../api/facilitator/endpoints';
import SelectEmail from '../../Components/InputTag/SelectEmail';
import { CountryList } from './Country';
import Cookies from 'js-cookie';
import ConfirmNewFarmModal from '../../Components/Modals/ConfirmModal';
import PhoneInput from 'react-phone-number-input';
import {
  country_list,
  prefferedList,
  usa_state_list,
  canada_province_list,
} from './constants';
import { milk_sold_to_list } from '../FarmForm/constants';
import { API_KEY } from '../../api/config';
import { useJsApiLoader } from '@react-google-maps/api';
let facilitatorList;
const libraries = ['places'];

const CreateFarm = () => {
  const { mutate: addNewFarmData, isLoading: farmDataLoading } =
    useAddNewFarm();
  const { isLoaded: PlaceLoader } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
    libraries,
  });
  const inputRef = useRef();
  const { selectedProject } = useSelector((state) => state.project);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const userData = JSON.parse(Cookies.get('user_data'));

  const [milkSoldList, setMilkSoldList] = useState(milk_sold_to_list);
  const [selectedCountry, setSelectedCountry] = useState('');
  // const [facilitatorList, setFacilitatorList] = useState()
  const initialState = [
    {
      label: 'Facilitator Representative',
      type: 'search',
      values: {
        name: userData.first_name + ' ' + userData.last_name,
        id: userData.id,
      },
      error: false,
      id: 'facilitator',
      list: facilitatorList,
      isDisplay: true,
    },
    {
      label: 'Dairy Farm Legal Name (include any suffix like Inc. or LLC)',
      type: 'text',
      values: '',
      error: false,
      id: 'farm_name',
      isDisplay: true,
    },
    {
      label: 'Name of the Person with Signing Authority [e.g.John Doe]',
      type: 'text',
      values: '',
      error: false,
      id: 'owner_name',
      isDisplay: true,
    },
    {
      label: 'Title/Designation of Signing Party [e.g.farm owner,dairy boss]',
      type: 'text',
      values: '',
      error: false,
      id: 'owner_title',
      isDisplay: true,
    },
    {
      label: 'Preferred Point of Contact',
      type: 'dropdown',
      values: prefferedList[0].name,
      error: false,
      list: prefferedList,
      id: 'point_of_contact',
      isDisplay: true,
    },
    {
      label: 'Farmer Email',
      type: 'text',
      values: '',
      error: false,
      id: 'owner_email',
      isDisplay: true,
    },
    {
      label: 'Farmer Phone Number',
      type: 'phone-number',
      values: '',
      error: false,
      id: 'owner_phone',
      isDisplay: true,
    },
    {
      label: 'Farm Primary Address',
      type: 'address-auto',
      values: '',
      error: false,
      id: 'address',
      isDisplay: true,
    },
    {
      label: 'City',
      type: 'text',
      values: '',
      error: false,
      list: CountryList,
      id: 'city',
      isDisplay: true,
    },
    {
      label: 'Incorporated Province of Farm',
      type: 'dropdown',
      values: '',
      error: false,
      list: canada_province_list,
      id: 'state',
      isDisplay: true,
    },
    {
      label: 'Country',
      type: 'dropdown',
      values: '',
      error: false,
      list: country_list,
      id: 'country',
      isDisplay: true,
    },
    {
      label: 'ZIP',
      type: 'text',
      values: '',
      error: false,
      id: 'zip_code',
      isDisplay: true,
    },
    {
      label: 'Agolin Start Date',
      type: 'date',
      values: '',
      error: false,
      id: 'agolin_start_date',
      isDisplay: true,
    },
    {
      label: 'Cow Numbers on Agolin (lactating)',
      type: 'number',
      values: '',
      error: false,
      id: 'cow_numbers_on_agolin',
      isDisplay: true,
    },
    {
      label: 'Where the Milk is Sold to?',
      type: 'dropdown-tag',
      values: '',
      error: false,
      list: milkSoldList,
      id: 'milk_sold_to',
      isDisplay: true,
    },
  ];
  const [allFields, setAllFields] = useState(initialState);
  const [selectedValues, setSelectedValues] = useState([]);
  const [adminAreaLevel1LongName, setAdminAreaLevel1LongName] = useState('');
  const [confirmShow, setConfirmShow] = useState(false);
  const [data, setData] = useState({});
  useEffect(() => {
    if (selectedCountry) {
      const updatedFields = allFields.map((field) => {
        if (field.id === 'state') {
          return {
            ...field,
            list:
              selectedCountry === 'United States'
                ? usa_state_list
                : canada_province_list,
            label:
              selectedCountry === 'United States'
                ? 'Incorporated State of Farm'
                : 'Incorporated Province of Farm',
            error: false,
          };
        } else if (field.id === 'zip_code') {
          return {
            ...field,
            label:
              selectedCountry === 'United States'
                ? 'ZIP'
                : 'Postal Code',
          };
        }
        return field;
      });
      setAllFields(updatedFields);
    }
  }, [selectedCountry]);

  useEffect(() => {
    const updatedFields = allFields.map((field) => {
      if (field.type === 'dropdown-tag') {
        return { ...field, error: false };
      }
      return field;
    });
    setAllFields(updatedFields);
  }, [selectedValues.length]);

  const handleSubmit = (farmData) => {
    addNewFarmData(farmData, {
      onSuccess: ({ data }) => {
        if (data.status === 200 || data.status === 201) {
          toastSuccess(data.message);
          setAllFields(initialState);
          setSelectedValues([]);
        } else {
          toastFailure(data.message);
        }
      },
      onError: (error) => {
        toastFailure(error.message);
      },
    });
  };

  const handleChange = (name, e, type) => {
    if (
      type === 'text' ||
      type === 'address' ||
      type === 'date' ||
      type === 'number'
    ) {
      const updatedFields = allFields.map((field) => {
        if (field.label === name) {
          return { ...field, values: e.target.value, error: false };
        }
        return field;
      });
      setAllFields(updatedFields);
    }
  };
  const handleValueChange = (label, value) => {
    const updatedFields = allFields.map((fields) => {
      if (fields.label === label) {
        return { ...fields, values: value, error: false };
      }
      return fields;
    });
    setAllFields(updatedFields);
  };
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const adminAreaLevel1Component = place.address_components.find(
        (component) => component.types.includes('administrative_area_level_1')
      );
      // Extract the long_name
      if (adminAreaLevel1Component) {
        setAdminAreaLevel1LongName(adminAreaLevel1Component.long_name);
      }

      let city, zipCode, country;

      place.address_components.forEach((component) => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }

        if (component.types.includes('postal_code')) {
          zipCode = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      });
      const updatedFields = allFields.map((field) => {
        if (field.id === 'address') {
          return { ...field, values: place.formatted_address, error: false };
        }
        if (field.id === 'city') {
          return { ...field, values: city, error: false };
        }
        if (field.id === 'zip_code') {
          return { ...field, values: zipCode, error: false };
        }
        if (field.id === 'state') {
          let names =
            country === 'United States'
              ? usa_state_list.map((province) => province.name)
              : canada_province_list.map((province) => province.name);
          if (names.includes(adminAreaLevel1Component.long_name)) {
            if (country === 'United States')
              return {
                ...field,
                label: 'Incorporated State of Farm',
                list: usa_state_list,
                values: adminAreaLevel1Component.long_name,
                error: false,
              };
            return {
              ...field,
              values: adminAreaLevel1Component.long_name,
              error: false,
            };
          }
        }
        if (field.id === 'country') {
          let names = field.list.map((country) => country.name);
          if (names.includes(country)) {
            return { ...field, values: country, error: false };
          }
        }
        return field;
      });
      setAllFields(updatedFields);
    }
  };

  const handleChangeSearch = (name, e, type) => {
    setIsSearchLoading(true);
    const responseSearch = async () => {
      let res = await getFacilitatorList(e.target.value, selectedProject?.id);
      if (res.status === 200 || res.status === 201) {
        facilitatorList = res.data.result;
        setIsSearchLoading(false);
      } else {
        setIsSearchLoading(false);
      }
    };
    responseSearch();
    const updatedFields = allFields.map((field) => {
      if (field.label === name) {
        return {
          ...field,
          values: {
            name: e.target.value,
            id: null,
          },
          error: false,
        };
      }
      return field;
    });
    setAllFields(updatedFields);
  };
  const searchSelect = (name, item) => {
    const updatedFields = allFields.map((field) => {
      if (field.label === name) {
        return {
          ...field,
          values: {
            name: item.facilitator,
            id: item.id,
          },
          error: false,
        };
      }
      return field;
    });
    setAllFields(updatedFields);
  };

  const handlePhoneNumber = (value) => {
    const updatedFields = allFields.map((field) => {
      if (field.type === 'phone-number') {
        return { ...field, values: value, error: false };
      }
      return field;
    });
    setAllFields(updatedFields);
  };

  const handleSelect = (name, value) => {
    const updatedFields = allFields.map((field) => {
      if (field.label === name) {
        return { ...field, values: value, error: false };
      }
      return field;
    });
    setAllFields(updatedFields);
    if (name === 'Country') {
      setSelectedCountry(value);
    }
  };

  const handleSave = () => {
    let primaryAddErr = false;
    if (userData.role === 'Aggregator Admin') {
      toastFailure('You do not have permissions to create a new farm');
    } else {
      const updatedFields = allFields.map((field) => {
        if (field.type === 'search') {
          if (field.values.name.trim() === '' || field.values.id === null) {
            return { ...field, error: true };
          }
        } else if (field.type === 'dropdown-tag') {
          if (selectedValues.length <= 0) {
            return { ...field, error: true };
          }
        } else if (field.type === 'phone-number') {
          return { ...field };
          // } else if(field.type === "address-auto"){
          //   if(field.values===""){
          //     toastFailure("Please Select Primary Address from DropDown");
          //     primaryAddErr=true;
          //     return {...field}
          //   }
        } else {
          if (field.values.trim() === '') {
            return { ...field, error: true };
          }
        }
        return field;
      });
      setAllFields(updatedFields);

      const noErrors = updatedFields.every((field) => !field.error);
      if (!noErrors) {
        toastFailure('Please select all fields..');
      } else if (primaryAddErr) {
        setConfirmShow(false);
      } else {
        const formData = {};

        allFields.forEach((field) => {
          if (field.type === 'search') {
            formData[field.id] = field.values.id;
          } else if (field.type === 'dropdown-tag') {
            formData[field.id] = selectedValues.map((txt) =>
              txt
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            );
          } else {
            formData[field.id] = field.values;
          }
        });
        formData['project_id'] = selectedProject.id;
        setData({ ...formData });
        setConfirmShow(!confirmShow);
      }
    }
  };
  return (
    <>
      <div className="farm-container">
        <div className="">
          Please note that the information entered here will pre-populate the
          Aggregation Agreement. So, please ensure the information is accurate.
        </div>
        <br />
        <div className="fields">
          {allFields.map((items, index) => {
            return (
              <React.Fragment key={index}>
                {items.isDisplay ? (
                  <>
                    {items.type === 'dropdown' ? (
                      <>
                        <div className="field">
                          <InputBoxWithDropdown
                            list={items.list}
                            label={items.label}
                            value={items.values}
                            onSelect={handleSelect}
                            isError={items.error}
                            placeholder={
                              items.id === 'point_of_contact' ? true : false
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {items.type === 'address-auto' ? (
                          <>
                            <div className="field">
                              <InputAutoAddress
                                label={items.label}
                                value={items.values}
                                type={items.type}
                                isError={items.error}
                                handleValueChange={handleValueChange}
                                handlePlaceChanged={handlePlaceChanged}
                                inputRef={inputRef}
                                isLoaded={PlaceLoader}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {items.type === 'search' ? (
                              <>
                                <div className="field">
                                  <SearchInputBox
                                    label={items.label}
                                    type={items.type}
                                    value={items.values.name}
                                    handleChange={handleChangeSearch}
                                    isError={items.error}
                                    list={facilitatorList}
                                    isLoading={isSearchLoading}
                                    onSelect={searchSelect}
                                    disable={true}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {items.type === 'dropdown-tag' ? (
                                  <>
                                    <div className="field">
                                      <div className="input-box">
                                        <div className="label">
                                          {items.label}
                                          <span className="required">*</span>
                                        </div>
                                        <div>
                                          <SelectEmail
                                            checked={selectedValues}
                                            setChecked={setSelectedValues}
                                            allOptionsEmail={milkSoldList || []}
                                            setMilkSoldList={setMilkSoldList}
                                            // setEmails={setEmails}
                                            isError={items.error}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {items.type === 'phone-number' ? (
                                      <>
                                        <div className="field">
                                          <div className="input-box">
                                            <div className="label">
                                              {items.label}
                                              <span className="required">
                                                *
                                              </span>
                                            </div>
                                            <div>
                                              <PhoneInput
                                                name=""
                                                international
                                                limitMaxLength
                                                style={{
                                                  border: items.error
                                                    ? '1px solid #FF2626'
                                                    : `1px solid #e6e6fc`,
                                                  height: '50px',
                                                  borderRadius: '6px',
                                                  padding: '0px 10px',
                                                }}
                                                className="custom-phone-input"
                                                defaultCountry={'US'}
                                                countryCallingCodeEditable={
                                                  false
                                                }
                                                value={items.value}
                                                onChange={(value) => {
                                                  handlePhoneNumber(value);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="field">
                                          <InputBox
                                            label={items.label}
                                            type={items.type}
                                            value={items.values}
                                            handleChange={handleChange}
                                            isError={items.error}
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="field"></div>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>

        <div className="fields"></div>
        <div className="buttons">
          {selectedProject && selectedProject.allow_new_projects && (
            <Button
              text="Save"
              width="174px"
              handleClick={() => {
                handleSave();
              }}
              isLoading={farmDataLoading}
            />
          )}
        </div>
        <ConfirmNewFarmModal
          allFields={allFields}
          confirmShow={confirmShow}
          setConfirmShow={setConfirmShow}
          handleSave={handleSubmit}
          formData={data}
        ></ConfirmNewFarmModal>
      </div>
    </>
  );
};

export default CreateFarm;
