import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../Button/Button';
import { InputField } from '../InputFields/InputField';


const ProfileModal = ({
  userData,
  setMyProfileShow,
  myProfileShow,
  ChangePasswordClicked,
  changePassClicked,
  resetPasswordFields,
  handleChangePassword,
  setChangePassClicked,
  resetPasswordFun,
  isLoading,
  handleRemoveFields,
}) => {
  return (
    <Modal
      show={myProfileShow}
      onHide={() => {
        setMyProfileShow(false);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={changePassClicked ? 'custom-profile2' : 'custom-profile'}
    >
      <Modal.Body>
        <div className="custom-modal-content2">
          <div className="modal-title-cls">
            {changePassClicked ? 'Change Password' : 'My Profile'}
          </div>
          {changePassClicked ? (
            <>
              <div className="PasswordField" style={{ marginTop: '15px' }}>
                <div className="UserField">
                  <p className="label-text">Old Password</p>
                  <div className="">
                    <InputField
                      name="old_password"
                      type="text"
                      placeholder="Enter old password"
                      handleOnChange={(e) => handleChangePassword(e)}
                      value={resetPasswordFields.old_password}
                    />
                  </div>
                </div>
              </div>
              <div className="PasswordField" style={{ marginTop: '15px' }}>
                <div className="UserField">
                  <p className="label-text">New Password</p>
                  <div className="">
                    <InputField
                      name="new_password"
                      type="password"
                      placeholder="Enter New password"
                      handleOnChange={(e) => handleChangePassword(e)}
                      value={resetPasswordFields.new_password}
                    />
                  </div>
                </div>
              </div>
              <div className="PasswordField" style={{ marginTop: '15px' }}>
                <div className="UserField">
                  <p className="label-text">Confirm Password</p>
                  <div className="">
                    <InputField
                      name="confirm_password"
                      type="password"
                      placeholder="Enter confirm password"
                      handleOnChange={(e) => handleChangePassword(e)}
                      value={resetPasswordFields.confirm_password}
                    />
                  </div>
                </div>
              </div>
              <div className="cancle-modal-faci" style={{ gap: '15px' }}>
                <Button
                  text="Cancel"
                  bgColor="#788B9A"
                  handleClick={() => {
                    setChangePassClicked(false);
                    handleRemoveFields();
                  }}
                  // isLoading={isCallApi}
                />
                <Button
                  text="Save"
                  handleClick={() => {
                    resetPasswordFun();
                  }}
                  isLoading={isLoading}
                />
              </div>
            </>
          ) : (
            <>
              <div className="modal-body-content2">
                <div className="body-content1">
                  <div className="conten1-label">First Name</div>
                  <div className="conten1-value">{userData.first_name}</div>
                </div>
                <div className="body-content2">
                  <div className="conten1-label">Last Name</div>
                  <div className="conten1-value">{userData.last_name}</div>
                </div>
                <div className="body-content1">
                  <div className="conten1-label">Email</div>
                  <div className="conten1-value">{userData.email}</div>
                </div>
                <div className="body-content2">
                  <div className="conten1-label">Password</div>
                  <div className="conten1-value">*********</div>
                </div>
              </div>
              <div className="change-password">
                <Button
                  text="Change Password"
                  handleClick={() => {
                    ChangePasswordClicked();
                  }}
                  // isLoading={isCallApi}
                />
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;
