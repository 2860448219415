import axiosAuthMiddleware from './middleware/middleware';

export const get_method = async (url) => {
	const res = await axiosAuthMiddleware.request({
		url: url,
		method: 'GET',
		headers: {
			'Content-Type': 'text/plain',
		},
		});
	return res;
};

export const post_method = async (url, data) => {
	const res = await axiosAuthMiddleware.request({
		url: url,
		method: 'POST',
		headers: {
		'Content-Type': 'text/plain',
		},
		data: data,
	});
	return res;
};

export const patch_method = async (url, data) => {
	const res = await axiosAuthMiddleware.request({
		url: url,
		method: 'PATCH',
		headers: {
		'Content-Type': 'application/json',
		},
		data: data,
	});
	return res;
};


export const delete_method = async (url, data) => {
	const res = await axiosAuthMiddleware.request({
		url: url,
		method: 'DELETE',
		headers: {
		'Content-Type': 'text/plain',
		},
		data: data,
	});
	return res;
};