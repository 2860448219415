import axiosAuthMiddleware from '../middleware/middleware';

export const ContactUsApi = async (data) => {
    const url = `/contact_us`;

    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        data: data
    });
    return await res;
}