import React, { useEffect, useState } from 'react';
import './Login.css';
import LogoMrv from '../../../images/Auth/SVG 1.svg';
import { Button } from '../../../Components/Button/Button';
import { InputField } from '../../../Components/InputFields/InputField';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLogin } from '../../../api/auth/hooks';
import { toastFailure, toastSuccess } from '../../../Components/Toaster/Toast';
import {
  reduxUserData,
  reduxUserDataDetails,
  reduxUserPermission,
} from '../../../api/auth/authSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getAccessControl } from '../../../api/auth/endpoints';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const [inputValue, setInputValue] = useState({ email: '', password: '' });
  const [isCallApi, setIsCallApi] = useState(false);
  const [error, setError] = useState({ email: '', password: '' });

  const { mutate: loginUser, isLoading: isLoggingIn } = useLogin();

  useEffect(() => {
    const getToken = Cookies.get('auth_token');

    if (getToken) {
      navigate('/farm-portfolio');
    }
  }, []);

  const logInUser = (formData) => {
    loginUser(formData, {
      onSuccess: async ({ success, data }) => {
        if (data.status === 200 || data.status === 201) {
          Cookies.set('auth_token', data.result.token.access);
          setIsCallApi(false);
          const userData = {
            email: inputValue.email,
            token: data.result.token.access,
          };
          toastSuccess(data.message);
          dispatch(reduxUserData(userData));
          Cookies.set('user_data', JSON.stringify(data.user));
          dispatch(reduxUserDataDetails(data.user));
          const responseUserPermission = await getAccessControl();
          if (
            responseUserPermission.data.status === 200 ||
            responseUserPermission.data.status === 201
          ) {
            dispatch(reduxUserPermission(responseUserPermission.data.result));
          }
          if (state) {
            const path = `/farm-form/${state}`;
            navigate(path);
          } else {
            navigate('/farm-portfolio');
          }
        } else {
          toastFailure(data.message);
          setIsCallApi(false);
        }
      },
      onError: (error) => {
        toastFailure(error.response.data.message);
        setIsCallApi(false);
      },
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value.toString().trim(),
    });
    setError({ ...error, [name]: '' });
  };

  const handleSignIn = () => {
    // navigate('/farm-portfolio');
    let message;
    setIsCallApi(true);
    // if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(inputValue.username) === false) {
    //   setIsCallApi(false);
    //   message = 'Email format is not valid';
    //   toastFailure(message);
    // } else if (
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
    //     inputValue.password
    //   ) === false
    // ) {
    //   setIsCallApi(false);
    //   message =
    //     'Password must contain at least one small and capital letter, special characters and numbers.';
    //   toastFailure(message);
    // }
    if (inputValue.email.trim() === '') {
      message = 'Please enter a Email';
      toastFailure(message);
      setIsCallApi(false);
    } else if (inputValue.password.trim() === '') {
      message = 'Please enter a password';
      toastFailure(message);
      setIsCallApi(false);
    } else {
      dispatch(reduxUserPermission(null));

      logInUser(inputValue);
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    var code = e.keyCode || e.which;
    if (code === 13) {
      handleSignIn();
    }
  };
  return (
    <>
      <div className="main-container">
        <div className="logo">
          <img src={LogoMrv} alt="logo" />
        </div>
        <div className="login-container">
          <div className="inner-container">
            <div className="container2">
              <p className="heading-content">
                Seamlessly collect farm data to accurately calculate
                <br />
                carbon credits, ensuring a greener future while empowering
                <br />
                farmers with deserved financial rewards.
              </p>
              <ul className="heading-list" listStyle="disc">
                <li>Thoughtful role-based access and security features</li>
                <li>Efficient workflows to make every step easier</li>
                <li>
                  Solid documentation for carbon credit calculations and audits
                </li>
              </ul>
            </div>
            <div className="container1">
              <div className="content">
                <p className="login">Log In</p>
                <p className="text">
                  Enter your credentials to access <br />
                  your account
                </p>
                <div className="Fileds">
                  <div className="UserField">
                    <p className="label-text">Email</p>
                    <div className="">
                      <InputField
                        name="email"
                        type="text"
                        placeholder="Enter Email"
                        handleOnChange={(e) => handleChange(e)}
                        value={inputValue?.email}
                      />
                    </div>
                  </div>
                  <div className="PasswordField">
                    <div className="UserField">
                      <div className="password">
                        <p className="label-text">Password</p>
                        <p
                          className="label-forget"
                          onClick={() => navigate('/forgot-password')}
                        >
                          Forgot password?
                        </p>
                      </div>
                      <div className="">
                        <InputField
                          name="password"
                          type="password"
                          placeholder="Enter password"
                          handleOnChange={(e) => handleChange(e)}
                          value={inputValue?.password}
                          onEnterFunction={(e) => handleKeypress(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-component">
                  <Button
                    text="Sign in"
                    handleClick={handleSignIn}
                    isLoading={isCallApi}
                  />
                </div>
                <p className="login-footer">
                  By signing in, you agree to the{' '}
                  <a
                    href="https://dev.carboncredify.ai/terms-of-services"
                    target="_blank"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://dev.carboncredify.ai/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <span style={{ background: '#ffffff4d', padding: '0 5px' }}>
            contact us: <span className="mail">support@carboncredify.ai</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Login;
